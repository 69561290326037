<template>
  <div class="pages page48">
    <div class="titre">
      <h1>Le danger,</h1>
      <h2>c’est les autres</h2>
    </div>
    <div class="blocGauche">
      <p>Mais on observe que :</p>
      <p>
        Les accidents mortels de 2RM ont pourtant principalement lieu hors
        agglomération où les conditions de circulation sont plutôt fluides<br>
        (58 %
        des tués), sans tiers identifié, c'est-à-dire très souvent SEUL.
      </p>
      <p>
        Dans la plupart des cas, c'est le comportement de l'usager 2RM qui est
        la cause principale de l'accident (depuis 2000, la responsabilité des
        usagers de 2RM dans les accidents mortels reste toujours comprise entre
        58 et 76 %).
      </p>
      <p>
        La vitesse excessive ou inadaptée aux circonstances, la consommation
        d'alcool, de stupéfiants ou de médicaments, l'inattention et
        l'inexpérience, l'excès de confiance en soi, la fatigue, le manque de
        respect pour la règle et la signalisation sont autant de
         causes identifiées d'accidents.
      </p>
    </div>
    <div class="blocDroit">
      <figure><img src="../assets/regle-moto_accident.jpg" alt="" /></figure>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page48",
};
</script>

<style lang="scss" scoped>
.page48 {
  .blocGauche {
    width: 40vw;
  }

  .blocDroit {
    position: absolute;
    top: 0vh;
    right: 0vw;
    background: linear-gradient(to right, transparent 21%, #f68c59 21%);
    width: 45vw;
    height: 100%;
    margin: 0;

    img {
      width: 28vw;
      display: block;
      margin-top: 5vh;
    }
  }
}
</style>
<template>
  <div class="pages page33">
    <div class="titre">
        <h1>Déboitement non signalé</h1>
    </div>
    <div class="blocGauche">
      <miniPlayer
        url="https://player.vimeo.com/progressive_redirect/playback/828952061/rendition/1080p/file.mp4?loc=external&signature=893dace16255feda195764ea6a46391ea7b0cfa9436be4419f42156fe5828fbd"
        thumbnail="thumbnail-deboitement.jpg"
      ></miniPlayer>
    </div>
    <div class="blocDroit">
      <img src="@/assets/deboitement3.jpg" alt="" />
    </div>
    <div class="blocTexte">
      <p>
        Un véhicule de tourisme situé sur la voie centrale procède à un changement de file brutal pour doubler un autre véhicule.<br>Il coupe la trajectoire d’un motocycliste qui voulait lui-même le dépasser.
      </p>
    </div>
  </div>
</template>

<script>
import miniPlayer from "@/components/miniPlayer.vue";

export default {
  name: "Page33",

  components: {
    miniPlayer,
  },
};
</script>


<style lang="scss">
.page33 {
  .blocGauche,
  .blocDroit {
    display: inline-block;
    width: 40vw;
    padding: 0 1vw;
    overflow: hidden;
    height: 43vh;
    margin: 0 2.4vw;
  }

  .blocDroit {
    img {
      width: 40vw;
    }
  }

  .blocTexte {
    p {
        text-align: center;
    }
  }
}
</style>

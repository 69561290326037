<template>
  <div class="pages page29">
    <div class="titre">
      <h1>Quelques notions</h1>
      <h2>sur l’hypovigilance</h2>
    </div>
    <p class="commentaire">À vous de jouer !</p>
    <p class="commentaire texteOrange">
      Que faire pour prévenir l’hypovigilance ?
    </p>
    <div class="blocReponse" @click="clickAnswer($event)">
      <div class="dot-flashing"></div>
      <div class="slideshow-container hidden">
        <div class="slideshow-prev">
          <a class="prev" @click="navSlides(-1)">&#10094;</a>
        </div>
        <div class="slideshow-content">
          <div class="slide fade">
            <p class="slideTitle">Pour les 2RM...</p>
            <ul>
              <li>Faire des pauses toutes les 2 h</li>
              <li>Régler la position de conduite</li>
              <li>Aérer le casque en ouvrant la visière régulièrement</li>
              <li>Si un régulateur de vitesse est disponible, le désengager
                régulièrement pour reprendre la main
              </li>
            </ul>
          </div>
          <div class="slide fade">
            <p class="slideTitle">Mais aussi pour les autres véhicules…</p>
            <ul>
              <li>Faire des pauses toutes les 2 h</li>
              <li>Aérer régulièrement l’habitacle</li>
              <li>Régler la climatisation sur une température modérée (pas trop de
                chaleur)
              </li>
              <li>Écouter la radio</li>
              <li>Désengager le régulateur de vitesse régulièrement pour reprendre
                la main
              </li>
              <li>Echanger avec les passagers</li>
            </ul>
          </div>
          <div class="slide fade">
            <p class="slideTitle">Préparer son déplacement</p>
            <ul>
              <li>Se reposer avant de partir</li>
              <li>Choisir son horaire de départ, son itinéraire, ses lieux de
                pause
              </li>
              <li>Manger légèrement</li>
              <li>Ne pas consommer d’alcool, de produits stupéfiants, de
                médicaments contre-indiqués évidemment !
              </li>
              <li>Ne pas hésiter à différer son départ en cas d’insuffisance de
                sommeil
              </li>
              <li>Eviter de partir sur les créneaux de grands départs en vacances
              </li>
            </ul>
          </div>
        </div>
        <div class="slideshow-next">
          <a class="next" @click="navSlides(1)">&#10095;</a>
        </div>
        <div class="dots-selector">
          <span class="dot" @click="currentSlide(1)"></span>
          <span class="dot" @click="currentSlide(2)"></span>
          <span class="dot" @click="currentSlide(3)"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page29",

  data() {
    return {
      slideIndex: 1,
    };
  },

  methods: {
    clickAnswer: function (e) {
      let target = e.target;

      if (!e.target.classList.contains("blocReponse")) {
        target = e.target.parentElement;
      }

      if (!target.firstElementChild.classList.contains("hidden") && (!target.firstElementChild.classList.contains("dot"))) {
        target.firstElementChild.classList.add("hidden");
        target.lastElementChild.classList.remove("hidden");
      }
    },

    navSlides: function(n) {
        this.showSlide(this.slideIndex += n);
    },

    currentSlide: function(n) {
        this.showSlide(this.slideIndex = n);
    },

    showSlide: function (n) {
      let i;
      let slides = document.getElementsByClassName("slide");
      let dots = document.getElementsByClassName("dot");

      if (n > slides.length) {
        this.slideIndex = 1;
      }
      if (n < 1) {
        this.slideIndex = slides.length;
      }

      for (i = 0; i < slides.length; i++) {
        slides[i].classList.add("hidden");
      }

      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }

      slides[this.slideIndex - 1].classList.remove("hidden");
      dots[this.slideIndex - 1].className += " active";
    },
  },

  mounted() {
    this.showSlide(this.slideIndex);
  },
};
</script>

<style lang="scss" scoped>
.page29 {
  .blocReponse {
    height: 36vh;
    cursor: help;
  }

  ul {
    list-style-type: none;
    padding-left: 1vw;

    li:before {
      content: "\25CF";
      margin-right: 1vw;
      color: #f68c59;
    }

    li {
      font-size: 1.6rem;
    }
  }

  .dot-flashing {
    top: 11vh;
    left: 45vw;
  }

  .slideshow-container {
    max-width: 100vw;
    height: 100%;
    position: relative;
    top: -1vh;
    margin: auto;

    .slideshow-content {
      margin: 0 5vw;

      .slide {

        position: relative;
        top:1vh;

        &:first-child {
            ul{
                margin-top:5vh;
            }
        }

        .slideTitle {
            text-align: center;
            font-size: 1.6rem;
            font-weight: bold;
        }

        
        
      }
    }

    .prev,
    .next {
      cursor: pointer;
      position: absolute;
      top: 50%;
      width: auto;
      margin-top: -22px;
      padding: 12px 20px;
      color: white;
      font-weight: bold;
      font-size: 21px;
      transition: 0.6s ease;
      border-radius: 30px;
      background-color: #034ea2;
      user-select: none;
    }

    .next {
      right: 0;
      border-radius: 30px;
    }

    .prev:hover,
    .next:hover {
      background-color: rgba(3, 78, 162, 0.4);
    }

    .dot {
      cursor: pointer;
      height: 15px;
      width: 15px;
      margin: 0 2px;
      background-color: #034ea2;
      border-radius: 50%;
      display: inline-block;
      transition: background-color 0.6s ease;
    }

    .active,
    .dot:hover {
      background-color: rgba(3, 78, 162, 0.2);
    }

    .fade {
      animation-name: fade;
      animation-duration: 1.5s;
    }

    @keyframes fade {
      from {
        opacity: 0.4;
      }
      to {
        opacity: 1;
      }
    }
  }

  .dots-selector {
    position: absolute;
    left: 43vw;
    bottom: 0vh;
}

  .hidden {
    display: none;
  }
}
</style>
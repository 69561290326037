<template>
  <div class="pages page2">
    <div class="blocGauche">
      <div class="titre">
        <h1>L’accidentalité 2RM :</h1>
        <h2>La réalité en quelques chiffres</h2>
      </div>
      <p>
        <strong>
          Les 2RM représentent environ 6,7 % du parc roulant
          motorisé
        </strong> et ne réalisent que 1,74 % du nombre total de km parcourus par
        des véhicules en France.
      </p>
      <p>
        Pourtant, <strong>
          les conducteurs de 2RM représentent 22 % des
          usagers motorisés tués sur les routes
        </strong>
        <strong>(594 motocyclistes et 124 cyclomotoristes)</strong> et
        <strong>33 % des blessés graves</strong> (dans les années 90, ils ne
        représentaient «que» 10 % des tués).
      </p>
      <p class="commentaire">
        On a 27 fois plus de risque d'être tué en 2RM<br> qu'en voiture – 7 fois
        plus qu'à vélo
      </p>
    </div>
    <div class="blocDroit">
      <h3>11,8 km</h3>
      <p>c’est la distance moyenne d’un déplacement à 2RM.</p>
      <h3>13 km/h</h3>
      <p>C'est environ la vitesse moyenne des 2RM, tout axe confondu.</p>
    </div>
    <div class="blocImage"><img src="@/assets/moto.png" alt="" /></div>
    <p class="basPage">Source ONISR 2022</p>
  </div>
</template>

<script>
export default {
  name: "Page2",
};
</script>


<style lang="scss">
.page2 {
  .blocGauche {
    width: 40vw;
  }

  .blocDroit {
    position: absolute;
    right: 0;
    top: 0;
    width: 26vw;
    height: 100%;
    padding-left: 10vw;
    padding-top: 5vh;
    padding-right: 2vw;
    background-color: #f68c59;
    color: white;

    h3 {
      font-size: 5rem;
      margin-top: 1vh;
      margin-bottom: 0;
    }

    p {
      font-size: 1.6rem;
      margin-top: 0;
      margin-bottom: 6vh;
    }
  }

  .blocImage {
    position: absolute;
    bottom: -6px;
    left: 44vw;
    img {
      width: 25vw;
    }
  }

  .commentaire {
    margin-top: 6vh;
  }
}
</style>
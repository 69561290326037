<template>
  <div class="gauge">
    <canvas id="canvas" width="200" height="170"></canvas>
    <div>
      <button @click="decrease()">-</button>
      <p>{{ speed }} <span>km/h</span></p>
      <button @click="increase()">+</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "gauge",

  props: {
    vitesse: [Number, Array],
  },

  data() {
    return {
      speed: 0,
    };
  },

  methods: {
    increase: function () {
      if (typeof this.vitesse === "object") {
        const index = this.vitesse.findIndex(
          (actualSpeed) => actualSpeed === this.speed
        );
        if (index < this.vitesse.length - 1) {
          this.speed = this.vitesse[index + 1];
        }
      } else {
        if (this.speed <= 140) {
          this.speed = this.speed + 10;
        }
      }
    },

    decrease: function () {
      if (typeof this.vitesse === "object") {
        const index = this.vitesse.findIndex(
          (actualSpeed) => actualSpeed === this.speed
        );
        if (index > 0) {
          this.speed = this.vitesse[index - 1];
        }
      } else {
        if (this.speed >= 40) {
          this.speed = this.speed - 10;
        }
      }
    },

    drawGauge: function (vitesse) {

      let canvas = this.$el.querySelector("canvas");

      if (canvas.getContext) {
        let ctx = canvas.getContext("2d");

        ctx.save();

        ctx.setTransform(1, 0, 0, 1, 0, 0);
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.translate(100, 100);
        ctx.scale(0.8, 0.8);

        ctx.lineWidth = 2;

        // Fond
        ctx.save();
        ctx.beginPath();
        ctx.fillStyle = "White";
        ctx.arc(0, 0, 124, 2.48, 0.68, false);
        ctx.closePath();
        ctx.stroke();
        ctx.fill();

        ctx.restore();

        ctx.rotate(Math.PI * 0.8);

        // Barre principale
        ctx.save();
        for (let i = 0; i < 16; i++) {
          ctx.beginPath();

          ctx.moveTo(100, 0);
          ctx.lineTo(120, 0);
          ctx.stroke();
          ctx.rotate((Math.PI / 15) * 1.4);
        }
        ctx.restore();

        //Barre secondaire
        ctx.save();
        for (let i = 0; i < 31; i++) {
          if (i % 2 !== 0) {
            ctx.beginPath();
            ctx.moveTo(110, 0);
            ctx.lineTo(120, 0);
            ctx.stroke();
          }
          ctx.rotate((Math.PI / 30) * 1.4);
        }
        ctx.restore();

        //petite division
        ctx.save();
        for (let i = 0; i < 61; i++) {
          if (i % 2 !== 0) {
            ctx.beginPath();
            ctx.moveTo(115, 0);
            ctx.lineTo(120, 0);
            ctx.stroke();
          }
          ctx.rotate((Math.PI / 60) * 1.4);
        }
        ctx.restore();

        ctx.rotate((Math.PI / 15) * 1.4 * (vitesse / 10));

        ctx.save();

        ctx.lineWidth = 1;
        ctx.fillStyle = "red";
        ctx.strokeStyle = "red";
        ctx.shadowOffsetX = 2;
        ctx.shadowOffsetY = 2;
        ctx.shadowBlur = 2;
        ctx.shadowColor = "rgba(0, 0, 0, 0.4)";
        ctx.beginPath();
        ctx.moveTo(0, 4);
        ctx.lineTo(112, 0);
        ctx.lineTo(0, -4);
        ctx.closePath();
        ctx.stroke();
        ctx.fill();

        ctx.restore();
        ctx.restore();
      }
    },
  },

  mounted() {
 
    if (typeof this.vitesse === "object") {
      this.speed = this.vitesse[0];
    } else {
      this.speed = this.vitesse;
    }
    this.drawGauge(this.speed);
  
  },


  beforeUpdate() {
    this.drawGauge(this.speed);
    this.$emit("speedUpdate", this.speed, this.$el.parentNode);
  },
};
</script>

<style lang="scss" scoped>
.gauge {
  width: 200px;
  div {
    text-align: center;
    position: relative;
    bottom: 1vh;

    button,
    p {
      display: inline-block;
    }

    button {
      background-color: #034ea2;
      border: 0;
      color: white;
      font-size: 1.2rem;
      font-weight: bold;
      width: 2vw;
      cursor: pointer;
    }

    p {
      font-size: 1.4rem;
      font-weight: bold;
      margin: 0px 0.4vw;
      width: 6vw;
      text-align: center;
    }

    span {
      font-size: 1rem;
    }
  }
}
</style>

<template>
  <div class="pages page18">
    <div class="blocGauche">
      <div class="titre">
        <h1>Médicaments</h1>
        <h2>Et risques d'accident</h2>
      </div>
      <p>
        Médicaments : plus de 3% des accidents de la route sont
        imputables à une consommation de médicaments.
      </p>
      <p class="commentaire">
        Cela représente environ 120 tués et 2500 blessés chaque année...
      </p>
      <p class="basPage">Source ONISR 2021</p>
    </div>
    <div class="blocDroit">
      <img src="../assets/code-medicament.png" alt="" />
    </div>
    <figure><img src="@/assets/medicament.jpg" alt="" /></figure>
  </div>
</template>

<script>
export default {
  name: "Page18",
};
</script>

<style lang="scss" scoped>
.page18 {
  .blocGauche {
    width: 40vw;
  }

  .blocDroit {
    position: absolute;
    right: 0;
    top: 0;
    width: 26vw;
    height: 100%;
    padding-left: 10vw;
    padding-top: 5vh;
    padding-right: 2vw;
    background-color: #f68c59;
    color: white;

    img {
        margin-top: 5vh;
    }
  }

    figure {
    position: absolute;
    bottom: -24px;
    left: 43vw;
    img {
      width: 25vw;
    }
  }
}
</style>
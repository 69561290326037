<template>
<div class="pages page56">
    <div class="titre">
        <h1>Zones de non visibilité</h1>
    </div>
    <div class="blocGauche">
        <p>S’assurer que l’on soit vu des autres usagers (rétroviseurs, regard de l’autre...).</p>
        <p>Éviter les manœuvres hasardeuses si vous avez peu de visibilité.</p>
        <p>Utiliser les avertisseurs de changement de direction après les vérifications nécessaires, afin d’assurer sa sécurité et de ne pas mettre en danger la vie des autres usagers.</p>
    </div>
    <figure><img src="@/assets/moto-retroviseur.jpg" alt=""></figure>
</div>
</template>

<script>
export default {
  name: "Page56",
};
</script>

<style lang="scss" scoped>
.page56 {

    .blocGauche {
        width: 45vw;
    }
  figure {
    position: absolute;
    top: 0vh;
    right: 0vw;
    background: linear-gradient(to right, transparent 21%, #f68c59 21%);
    width: 45vw;
    height: 100%;
    margin: 0;


    img {
      width: 24vw;
      display: block;
      margin-top: 8vh;
    }
  }
}
</style>
<template>
  <div class="pages page51">
    <div class="titre">
      <h1>Temps de réaction</h1>
      <h2>et anticipation</h2>
    </div>
    <div class="blocTexte">
      <p>
        Le temps de réaction se situe entre le moment où l’on voit l’obstacle et
        le moment où l’on commence à freiner (action physique déclenchée par un
        ordre du cerveau.)
      </p>
      <p>
        Pendant ce laps de temps, le 2RM continue de parcourir de la distance.
      </p>
      <p class="commentaire">
        Pour un individu normal, le temps de réaction est d’environ 1 seconde.
      </p>
    </div>
    <div class="blocGauche">
      <p>
        à <img src="../assets/vitesse30.png" alt="" />, je parcours
        <strong>9 m</strong>
      </p>
      <p>
        à <img src="../assets/vitesse50.png" alt="" />, je parcours
        <strong>15 m</strong>
      </p>
      <p>
        à <img src="../assets/vitesse90.png" alt="" />, je parcours
        <strong>27 m</strong>
      </p>
      <p>
        à <img src="../assets/vitesse110.png" alt="" />, je parcours
        <strong>33 m</strong>
      </p>
      <p>
        à <img src="../assets/vitesse130.png" alt="" />, je parcours
        <strong>39 m</strong>
      </p>
    </div>
    <div class="blocDroit">
      <p class="sousTitre">À retenir :</p>
      <p>
        <strong
          >Distance de freinage parcourue pendant le temps de réaction :</strong
        >
        à 50 km/h, en 1 sec, je parcours 3 X 5 (chiffre des dizaines) = 15
        mètres.
      </p>
      <p>
        <strong>Distance d'arrêt :</strong> le chiffre des dizaines multiplié
        par lui-même.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page51",
};
</script>

<style lang="scss" scoped>
.page51 {
  .blocTexte {
    position: relative;
    top: -1vh;
  }

  .blocGauche,
  .blocDroit {
    display: inline-block;
  }

  .blocGauche {
    width: 40vw;
    padding-left: 9vw;
    position: relative;
    top: -3vh;

    p {
      margin: 1vh 0;
    }
    img {
      vertical-align: middle;
      width: 3vw;
    }
  }

  .blocDroit {
    width: 40vw;
    position: relative;
    top: -10vh;

    .sousTitre {
      color: #f68c59;
      font-weight: bold;
    }
  }
}
</style>
<template>
  <div class="pages page15">
    <div class="titre">
      <h1>Soirée entre jeunes</h1>
    </div>
    <img src="@/assets/repas_jeunes.png" alt="" />
  </div>
</template>

<script>
export default {
  name: "Page15",
};
</script>

<style lang="scss">
.page15 {
  img {
    position: relative;
    bottom: 136px;
  }
}
</style>
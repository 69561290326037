<template>
  <div class="pages page54">
    <div class="blocGauche">
      <div class="titre">
        <h1>Zones de non visibilité</h1>
        <h2>Angles morts...</h2>
      </div>
      <p>
        Prendre en compte la différence de gabarit des autres usagers de la
        route.
      </p>
      <p>
        Avant toutes manœuvres, utiliser les équipements fournis par le
        véhicule, les retroviseurs.
      </p>
      <p>
        Jeter de brefs regards sur les côtés : les angles morts se réduisent si
        vous bougez votre tête, votre corps.
      </p>
    </div>
    <figure>
      <img src="@/assets/angles-mort01.jpg" alt="" /><img
        src="@/assets/angles-mort-02.jpg"
        alt=""
      />
    </figure>
    <div class="blocModal hidden">
      <p>&#x2716;</p>
      <img src="@/assets/angles-mort01.jpg" alt="" class="slide">
      <img src="" alt="" class="slide">
    </div>
  </div>
</template>

<script>
export default {
  name: "Page54",
};
</script>

<style lang="scss" scoped>
.page54 {

    .blocGauche {
        width: 45vw;
    }
  figure {
    position: absolute;
    top: -3vh;
    right: 4vw;

    img {
      width: 37vw;
      display: block;
      margin: 3vh 0;
    }
  }

    .blocModal {
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    margin: 0;
    position: absolute;
    left: 0px;
    top: 0;

    p {
      color: rgba(255, 255, 255,.4);
      font-size: 1.6rem;
      font-weight: bold;
      padding-left: 96vw;
    }

    img {
        width: 66vw;
        margin: 3vh 17vw;
    }
  }

  .hidden {
    display: none;
  }
}
</style>
<template>
  <div class="pages page38">
    <div class="titre">
      <h1>Sortie de parking</h1>
    </div>
    <div class="blocGauche">
      <miniPlayer
        url="https://player.vimeo.com/progressive_redirect/playback/769916213/rendition/1080p/file.mp4?loc=external&signature=84b990c5a6854255c487eb8ce1630963e66506f5cf0e6513bf53526a99890a2d"
        thumbnail="thumbnail-parking.jpg"
      ></miniPlayer>
    </div>
    <div class="blocDroit">
      <img src="@/assets/parking.jpg" alt="" />
    </div>
    <div class="blocTexte">
      <p>
        Un conducteur de 2RM voit sa trajectoire coupée par un véhicule de
        tourisme effectuant une sortie de parking.
      </p>
    </div>
  </div>
</template>

<script>
import miniPlayer from "@/components/miniPlayer.vue";

export default {
  name: "Page38",

  components: {
    miniPlayer,
  },
};
</script>


<style lang="scss">
.page38 {
  .blocGauche,
  .blocDroit {
    display: inline-block;
    width: 40vw;
    padding: 0 1vw;
    overflow: hidden;
    height: 43vh;
    margin: 0 2.4vw;
  }

  .blocDroit {
    img {
      width: 40vw;
    }
  }

    .blocTexte {
    p {
        text-align: center;
    }
  }
}
</style>
<template>
  <div class="pages page1">
    <miniPlayer
      url="https://player.vimeo.com/progressive_redirect/playback/832259483/rendition/1080p/file.mp4?loc=external&signature=514f68e5e561cecfd3c1f8eed4a5633b9571a3e14127dfe21cce9f91748a1d2a"
      thumbnail="thumbnail-intro.jpg"
    ></miniPlayer>
  </div>
</template>

<script>
import miniPlayer from "@/components/miniPlayer.vue";

export default {
  name: "Page1",

  components: {
    miniPlayer,
  },
};
</script>


<style lang="scss" scoped>
.page1 {
  .videoWrapper {
      width: 64vw;
  }
}
</style>
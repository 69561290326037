<template>
  <div id="content">
    <page1 v-if="$route.params.id_page == 1"></page1>
    <page2 v-if="$route.params.id_page == 2"></page2>
    <page3 v-if="$route.params.id_page == 3"></page3>
    <page4 v-if="$route.params.id_page == 4"></page4>
    <page5 v-if="$route.params.id_page == 5"></page5>
    <page6 v-if="$route.params.id_page == 6"></page6>
    <page7 v-if="$route.params.id_page == 7"></page7>
    <page8 v-if="$route.params.id_page == 8"></page8>
    <page9 v-if="$route.params.id_page == 9"></page9>
    <page10 v-if="$route.params.id_page == 10"></page10>
    <page11 v-if="$route.params.id_page == 11"></page11>
    <page12 v-if="$route.params.id_page == 12"></page12>
    <page13 v-if="$route.params.id_page == 13"></page13>
    <page14 v-if="$route.params.id_page == 14"></page14>
    <page15 v-if="$route.params.id_page == 15"></page15>
    <page16 v-if="$route.params.id_page == 16"></page16>
    <page17 v-if="$route.params.id_page == 17"></page17>
    <page18 v-if="$route.params.id_page == 18"></page18>
    <page19 v-if="$route.params.id_page == 19"></page19>
    <page20 v-if="$route.params.id_page == 20"></page20>
    <page21 v-if="$route.params.id_page == 21"></page21>
    <page22 v-if="$route.params.id_page == 22"></page22>
    <page23 v-if="$route.params.id_page == 23"></page23>
    <page24 v-if="$route.params.id_page == 24"></page24>
    <page25 v-if="$route.params.id_page == 25"></page25>
    <page26 v-if="$route.params.id_page == 26"></page26>
    <page27 v-if="$route.params.id_page == 27"></page27>
    <page28 v-if="$route.params.id_page == 28"></page28>
    <page29 v-if="$route.params.id_page == 29"></page29>
    <page30 v-if="$route.params.id_page == 30"></page30>
    <page31 v-if="$route.params.id_page == 31"></page31>
    <page32 v-if="$route.params.id_page == 32"></page32>
    <page33 v-if="$route.params.id_page == 33"></page33>
    <page34 v-if="$route.params.id_page == 34"></page34>
    <page35 v-if="$route.params.id_page == 35"></page35>
    <page36 v-if="$route.params.id_page == 36"></page36>
    <page37 v-if="$route.params.id_page == 37"></page37>
    <page38 v-if="$route.params.id_page == 38"></page38>
    <page39 v-if="$route.params.id_page == 39"></page39>
    <page40 v-if="$route.params.id_page == 40"></page40>
    <page41 v-if="$route.params.id_page == 41"></page41>
    <page42 v-if="$route.params.id_page == 42"></page42>
    <page43 v-if="$route.params.id_page == 43"></page43>
    <page44 v-if="$route.params.id_page == 44"></page44>
    <page45 v-if="$route.params.id_page == 45"></page45>
    <page46 v-if="$route.params.id_page == 46"></page46>
    <page47 v-if="$route.params.id_page == 47"></page47>
    <page48 v-if="$route.params.id_page == 48"></page48>
    <page49 v-if="$route.params.id_page == 49"></page49>
    <page50 v-if="$route.params.id_page == 50"></page50>
    <page51 v-if="$route.params.id_page == 51"></page51>
    <page52 v-if="$route.params.id_page == 52"></page52>
    <page53 v-if="$route.params.id_page == 53"></page53>
    <page54 v-if="$route.params.id_page == 54"></page54>
    <page55 v-if="$route.params.id_page == 55"></page55>
    <page56 v-if="$route.params.id_page == 56"></page56>
    <page57 v-if="$route.params.id_page == 57"></page57>
    <page58 v-if="$route.params.id_page == 58"></page58>
    <page59 v-if="$route.params.id_page == 59"></page59>
    <page60 v-if="$route.params.id_page == 60"></page60>
    <page61 v-if="$route.params.id_page == 61"></page61>
    <page62 v-if="$route.params.id_page == 62"></page62>
    <page63 v-if="$route.params.id_page == 63"></page63>
    <page64 v-if="$route.params.id_page == 64"></page64>
    <page65 v-if="$route.params.id_page == 65"></page65>
  </div>
</template>

<script>
import page1 from "@/pages/page1.vue";
import page2 from "@/pages/page2.vue";
import page3 from "@/pages/page3.vue";
import page4 from "@/pages/page4.vue";
import page5 from "@/pages/page5.vue";
import page6 from "@/pages/page6.vue";
import page7 from "@/pages/page7.vue";
import page8 from "@/pages/page8.vue";
import page9 from "@/pages/page9.vue";
import page10 from "@/pages/page10.vue";
import page11 from "@/pages/page11.vue";
import page12 from "@/pages/page12.vue";
import page13 from "@/pages/page13.vue";
import page14 from "@/pages/page14.vue";
import page15 from "@/pages/page15.vue";
import page16 from "@/pages/page16.vue";
import page17 from "@/pages/page17.vue";
import page18 from "@/pages/page18.vue";
import page19 from "@/pages/page19.vue";
import page20 from "@/pages/page20.vue";
import page21 from "@/pages/page21.vue";
import page22 from "@/pages/page22.vue";
import page23 from "@/pages/page23.vue";
import page24 from "@/pages/page24.vue";
import page25 from "@/pages/page25.vue";
import page26 from "@/pages/page26.vue";
import page27 from "@/pages/page27.vue";
import page28 from "@/pages/page28.vue";
import page29 from "@/pages/page29.vue";
import page30 from "@/pages/page30.vue";
import page31 from "@/pages/page31.vue";
import page32 from "@/pages/page32.vue";
import page33 from "@/pages/page33.vue";
import page34 from "@/pages/page34.vue";
import page35 from "@/pages/page35.vue";
import page36 from "@/pages/page36.vue";
import page37 from "@/pages/page37.vue";
import page38 from "@/pages/page38.vue";
import page39 from "@/pages/page39.vue";
import page40 from "@/pages/page40.vue";
import page41 from "@/pages/page41.vue";
import page42 from "@/pages/page42.vue";
import page43 from "@/pages/page43.vue";
import page44 from "@/pages/page44.vue";
import page45 from "@/pages/page45.vue";
import page46 from "@/pages/page46.vue";
import page47 from "@/pages/page47.vue";
import page48 from "@/pages/page48.vue";
import page49 from "@/pages/page49.vue";
import page50 from "@/pages/page50.vue";
import page51 from "@/pages/page51.vue";
import page52 from "@/pages/page52.vue";
import page53 from "@/pages/page53.vue";
import page54 from "@/pages/page54.vue";
import page55 from "@/pages/page55.vue";
import page56 from "@/pages/page56.vue";
import page57 from "@/pages/page57.vue";
import page58 from "@/pages/page58.vue";
import page59 from "@/pages/page59.vue";
import page60 from "@/pages/page60.vue";
import page61 from "@/pages/page61.vue";
import page62 from "@/pages/page62.vue";
import page63 from "@/pages/page63.vue";
import page64 from "@/pages/page64.vue";
import page65 from "@/pages/page65.vue";

export default {
  name: "Content",

  components: {
    page1,
    page2,
    page3,
    page4,
    page5,
    page6,
    page7,
    page8,
    page9,
    page10,
    page11,
    page12,
    page13,
    page14,
    page15,
    page16,
    page17,
    page18,
    page19,
    page20,
    page21,
    page22,
    page23,
    page24,
    page25,
    page26,
    page27,
    page28,
    page29,
    page30,
    page31,
    page32,
    page33,
    page34,
    page35,
    page36,
    page37,
    page38,
    page39,
    page40,
    page41,
    page42,
    page43,
    page44,
    page45,
    page46,
    page47,
    page48,
    page49,
    page50,
    page51,
    page52,
    page53,
    page54,
    page55,
    page56,
    page57,
    page58,
    page59,
    page60,
    page61,
    page62,
    page63,
    page64,
    page65,
  },

  methods: {
    toggleNextArrow: function() {

      var element = document.getElementById("next");
        
      if (this.appState[this.$route.params.id_page - 1].nextPage=="end") {
        element.classList.add("end");
      } else {
        element.classList.remove("end");
      }

    }
  },

  mounted() {
    this.toggleNextArrow();
  },

  updated() {
    this.toggleNextArrow();
  },
};
</script>

<style lang="scss">
#content {
  background: bottom no-repeat url("../assets/fond.png") rgba(246, 140, 89, 0.2);
  background-blend-mode: screen;
  height: 72vh;
  width: 100vw;
  position: absolute;
  z-index: 0;
  //padding: 0 3vw;
  top: 14vh;
}

.pages {
  margin: 4vh 3vw 0 3vw;
  height: 68vh;
  font-size: 1.15rem;
  text-align: left;

  h1 {
    color: #f68c59;
    font-size: 1.8em;
  }

  h2 {
    color: #f68c59;
    font-size: 2rem;
    text-align: left;
  }

  p {
    text-align: left;
  }

  sup {
    font-size: .6rem;
  }

  .texteOrange {
    color: #f68c59;
    font-weight: bold;
  }

  .chiffre {
    background-color: #f68c59;
    color: white;
    font-size: 3rem;
    font-weight: bold;
    padding: 3vh 3vw;
    text-align: center;
  }

  .basPage {
    position: absolute;
    bottom: 0;
    font-size: 1rem;
  }

  .commentaire {
    text-align: center;
    font-weight: bold;
    font-size: 1.6rem;
  }

  .commentaire2 {
    h3 {
      font-size: 5rem;
      margin-top: 1vh;
      margin-bottom: 2vh;
      line-height: 8vh;
    }

    p {
      font-size: 1.6rem;
      margin-top: 0;
      margin-bottom: 6vh;
    }
  }

  .titre {
    h1 {
      margin-bottom: 0;
      font-size: 2.6rem;
    }
    h2 {
      font-size: 1.7rem;
      //text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 0;
    }

    &:after {
      content: ""; /* This is necessary for the pseudo element to work. */
      display: block; /* This will put the pseudo element on its own line. */
      width: 5vw; /* Change this to whatever width you want. */
      margin-bottom: 4vh; /* This creates some space between the element and the border. */
      margin-top: 2vh;
      border-bottom: 1.4vh solid #f68c59; /* This creates the border. Replace black with whatever color you want. */
    }
  }

  .sousMenu {
    position: relative;
    //bottom: 64vh;
    left: 82vw;
    background-color: #034EA2;
    color: white;
    width: 10vw;
    font-size: 1.6rem;
    font-weight: bold;
    padding: 1vh 1vw;
    text-align: center;
  }

  /**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #034EA2;
  color: #034EA2;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -25px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #034EA2;
  color: #034EA2;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 25px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #034EA2;
  color: #034EA2;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #034ea2;
  }
  50%, 100% {
    background-color: rgba(3, 78, 162, 0.2);
  }
}
}
</style>
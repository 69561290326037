<template>
  <div class="pages page46">
    <div class="titre">
      <h1>Soyez vigilant et anticipez</h1>
      <h2>Les fautes des autres conducteurs</h2>
    </div>
    <div class="blocGauche">
      <p>
        Méfiez-vous de l’inattention des autres conducteurs, gare à ceux qui utilisent leur portable au volant.
      </p>
      <p>Attention :</p>
      <ul>
        <li>
          aux <strong>changements de direction brutaux</strong> , aux <strong>demi-tours</strong>
          et <strong>marches arrières</strong> sans précaution,
        </li>
        <li>
          aux <strong>franchissements de feux rouges</strong> par des
          bicyclettes, des EDPM et des rollers,
        </li>
        <li>
          aux <strong>traversées inopinées</strong> de chaussée par des
          piétons, aux véhicules en stationnement,
        </li>
        <li>
          aux <strong>véhicules en stationnement</strong>, dont la portière
          peut s’ouvrir brusquement,
        </li>
        <li>
          aux <strong>éventuelles arrivées de piétons</strong> entre deux
          véhicules en bordure de chaussée.
        </li>
      </ul>
    </div>
    <div class="blocDroit">
      <figure><img src="@/assets/regle-moto_slalom.jpg" alt="" /></figure>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page46",
};
</script>

<style lang="scss" scoped>
.page46 {
  .blocGauche {
    width: 40vw;
  }

  .blocDroit {
    position: absolute;
    top: 0vh;
    right: 0vw;
    background: linear-gradient(to right, transparent 21%, #f68c59 21%);
    width: 45vw;
    height: 100%;
    margin: 0;

    img {
      width: 28vw;
      display: block;
      margin-top: 5vh;
    }
  }
}
</style>
<template>
  <div class="pages page34">
    <div class="titre">
        <h1>Remontée de files</h1>
    </div>
    <div class="blocGauche">
      <miniPlayer
        url="https://player.vimeo.com/progressive_redirect/playback/771604470/rendition/1080p/file.mp4?loc=external&signature=280e26b439fb0209a56def3f1ff8630492a3a40682377235d073b7a9f9717c26"
        thumbnail="thumbnail-vitesse.jpg"
      ></miniPlayer>
    </div>
    <div class="blocDroit">
      <img src="@/assets/vitesse.jpg" alt="" />
    </div>
    <div class="blocTexte">
      <p>
        Le conducteur de 2RM ne respecte pas les règles de circulation dans le
        cadre de remontée de files, notamment par une vitesse inappropriée.
      </p>
    </div>
  </div>
</template>

<script>
import miniPlayer from "@/components/miniPlayer.vue";

export default {
  name: "Page34",

  components: {
    miniPlayer,
  },
};
</script>


<style lang="scss">
.page34 {
  .blocGauche,
  .blocDroit {
    display: inline-block;
    width: 40vw;
    padding: 0 1vw;
    overflow: hidden;
    height: 43vh;
    margin: 0 2.4vw;
  }

  .blocDroit {
    img {
      width: 40vw;
    }
  }

  .blocTexte {
    p {
        text-align: center;
    }
  }
}
</style>
<template>
  <div class="pages page13">
      <div class="titre">
        <h1>Alcool et risque d’accident</h1>
      </div>
      <img src="@/assets/alcool_et_risque.svg" alt="">
  </div>
</template>

<script>
export default {
  name: "Page13",
};
</script>

<style lang="scss">
.page13 {
img {
    position: relative;
    bottom: 120px;
}
}
</style>
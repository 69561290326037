<template>
  <div class="pages page5">
    <div class="titre">
      <h1>Formation obligatoire</h1>
      <h2>Pour la conduite de 125 cm<sup>3</sup> (permis A1)</h2>
    </div>
    <div class="blocGauche">
      <h3>À compter du 1<sup>er</sup> janvier 2011 : formation de 7 heures</h3>
      <p>
        Obligatoire pour tous les titulaires du permis B qui souhaitent conduire
        un deux-roues motorisé de 50 à 125 cm<sup>3</sup> et dont la puissance n'excède pas
        11 kW ou un "scooter à trois roues" de plus de 50 cm<sup>3</sup> et de plus de 15
        kW et qui n'en ont pas conduit au cours de ces 5 dernières années.
        <span class="texteOrange"
          >(Relevé d’information fourni par la compagnie d’assurance)</span
        >.
      </p>
      <p>
        À l'issue de cet enseignement, une
        <span class="texteOrange">attestation de suivi de formation</span>
        sera remise par l'école de conduite.
      </p>
      <h3>Depuis le 19 janvier 2013</h3>
      <p>
        Il est en plus nécessaire pour les usagers de scooters à 3 roues, de
        plus de 15 kW, d'être âgés de 21 ans révolus.
      </p>
    </div>
    <div class="blocDroit">
            <p>
        Le non respect de cette réglementation (non justification de
        l’attestation de formation ou d’un "relevé d'information") sera
        <span class="texteOrange">
          passible d' une amende de 4ème classe (135 €).
        </span>
      </p>
      <p>
        Attention : un relevé d'information "125 cm<sup>3</sup>" ne donne le droit de
        conduire qu'une 125 cm<sup>3</sup> et un relevé d'information "tricycle" ne donne le
        droit que de conduire un tricycle. Il n'y a pas de réciprocité, au
        contraire de la formation 7 heures.
      </p>
      <p>
        En cas d'invalidation ou d'annulation du permis de conduire, cette
        formation restera acquise.
      </p>
      <h3>Sur les 7 heures de formation :</h3>
      <ul>
        <li>2 heures sont attribuées à un enseignement théorique,</li>
        <li>
          2 heures sont consacrées à la prise en main du véhicule et aux
          vérifications indispensables,
        </li>
        <li>3 heures de pratique en circulation.</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page5",
};
</script>

<style lang="scss" scoped>
.page5 {
  .blocGauche,
  .blocDroit {
    width: 45vw;
    display: inline-block;
    vertical-align: top;
    position: relative;
    top: -4vh;
  }

    .blocGauche {
    margin-right: 2vw;
  }

  .blocDroit {
    margin-left: 2vw;
  }

  h3 {
    margin-bottom: 0;
  }
}
</style>
<template>
  <div class="pages page50">
    <div class="titre">
      <h1>Quelques conseils</h1>
      <h2>Pour limiter les risques</h2>
    </div>
    <div class="blocGauche">
      <p>
        Respectez les limitations de vitesse, adaptez votre vitesse aux
        circonstances.
      </p>
      <p>
        Avertissez les autres conducteurs lorsque vous vous apprêtez à dépasser.
      </p>
      <p>Respecter la distance de sécurité par rapport aux autres véhicules.</p>
      <p>Attention aux bandes de ciment isolant les couloirs de bus.</p>
      <p>Respectez la priorité aux intersections.</p>
      <p>
        Attention aux remontées entre deux files de véhicules en mouvement : en
        cas d’accident, vous engagez non seulement votre vie mais aussi votre
        responsabilité.
      </p>
      <p>
        Pour être visible, roulez toujours avec les feux de croisement allumés.
      </p>
      <p>
        Ne vous insérez pas entre le trottoir et un véhicule arrêté à un feu et
        signalant son intention de tourner à droite.
      </p>
    </div>
    <div class="blocDroit">
      <figure>
        <img src="../assets/regle-moto_illustration.jpg" alt="" />
      </figure>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page50",
};
</script>

<style lang="scss" scoped>
.page50 {
  .blocGauche {
    width: 45vw;
  }

  .blocDroit {
    position: absolute;
    top: 0vh;
    right: 0vw;
    width: 45vw;
    height: 100%;
    margin: 0;

    figure {
      margin: 0;
      position: relative;
      top: -5vh;
      left: 12vw;

      img {
        width: 26vw;
        margin-top: 5vh;
      }
    }
  }
}
</style>
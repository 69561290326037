<template>
  <div class="pages page6">
    <div class="titre">
      <h1>Expérimentation</h1>
      <h2>De la circulation inter-files</h2>
    </div>
    <div class="blocGauche">
      <h3>
        Où peut-on circuler en inter-files ?
        <span @click="showModal(1)"
          >&nbsp;&#x3E; voir l'illustration &#x3C;</span
        >
      </h3>
      <p>
        Entre les deux voies les plus à gauche d’une chaussée à au moins 2 fois
        2 voies, séparée d’un terre-plein central et où la vitesse autorisée est
        comprise entre 70 et 130 km/h.
      </p>
      <h3>
        Quand peut-on circuler en inter-files ?
        <span @click="showModal(2)"
          >&nbsp;&#x3E; voir l'illustration &#x3C;</span
        >
      </h3>
      <p>
        La circulation inter-files est autorisée lorsque la circulation est
        dense et qu’elle s’établit en files ininterrompues sur toutes les voies.
      </p>
      <figure><img src="@/assets/code-6-panneaux.png" alt="" /></figure>
      <p class="commentaire">
        La circulation inter-files est interdite lorsqu'une voie est en travaux
        ou couverte de neige ou de verglas.
      </p>
    </div>
    <div class="blocDroit">
      <h3>
        Quelles sont les règles à respecter ?<span @click="showModal(3)"
          >&nbsp;&#x3E; voir l'illustration &#x3C;</span
        >
      </h3>
      <p>
        Lorsque le trafic se fluidifie et que les véhicules circulent à plus de
        50 km/h sur au moins une des deux files, le deux roues motorisé en
        inter-files doit reprendre sa place dans le courant normal de la
        circulation.
      </p>
      <p class="commentaire">
        En inter-files, la vitesse est limitée à 50 km/h, mais il est essentiel
        d'adapter sa vitesse aux conditions de circulation. Il est interdit de
        dépasser un autre deux-roues motorisé circulant en inter-files.
      </p>
      <h3>
        Et bien sûr... respecter les distances de sécurité entre deux-roues
        motorisés en inter-files.
        <span @click="showModal(4)"
          >&nbsp;&#x3E; voir l'illustration &#x3C;</span
        >
      </h3>
      <p>
        Avant toute manœuvre, vérifier dans les rétroviseurs qu’aucun 2RM n’y
        circule et actionner les clignotants afin d’avertir les autres usagers
        de la route.
      </p>
      <p>
        Entretenir la courtoisie et le respect mutuel afin de partager la route
        en toute sécurité (faîtes un signe de remerciement)
      </p>
    </div>
    <div id="modal1" class="blocModal hidden">
      <h2>Où peut-on circuler en inter-files ?</h2>
      <figure><img src="@/assets/code-6-modal-1-1.png" alt="" /></figure>
      <figure><img src="@/assets/code-6-modal-1-2.png" alt="" /></figure>
      <p class="close" @click="closeModal($event)">&#x2716;</p>
    </div>
    <div id="modal2" class="blocModal hidden">
      <h2>Quand peut-on circuler en inter-files ?</h2>
      <figure><img src="@/assets/code-6-modal-2-1.png" alt="" /></figure>
      <figure><img src="@/assets/code-6-modal-2-2.png" alt="" /></figure>
      <p class="close" @click="closeModal($event)">&#x2716;</p>
    </div>
    <div id="modal3" class="blocModal hidden">
      <h2>Quelles sont les règles à respecter ?</h2>
      <figure><img src="@/assets/code-6-modal-3-1.png" alt="" /></figure>
      <figure><img src="@/assets/code-6-modal-3-2.png" alt="" /></figure>
      <p class="close" @click="closeModal($event)">&#x2716;</p>
    </div>
    <div id="modal4" class="blocModal hidden">
      <h2>
        Respecter les distances de sécurité entre deux-roues motorisés en
        inter-files.
      </h2>
      <figure><img src="@/assets/code-6-modal-4-1.png" alt="" /></figure>
      <figure><img src="@/assets/code-6-modal-4-2.png" alt="" /></figure>
      <p class="close" @click="closeModal($event)">&#x2716;</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page6",

  methods: {
    showModal: function (id) {
      let modal = document.querySelector("#modal" + id);

      modal.classList.remove("hidden");
    },

    closeModal: function (e) {
      e.target.parentElement.classList.add("hidden");
    },
  },
};
</script>

<style lang="scss" scoped>
.page6 {
  .blocGauche,
  .blocDroit {
    width: 45vw;
    display: inline-block;
    vertical-align: top;
    position: relative;
    top: -4vh;
  }

  .blocGauche {
    margin-right: 2vw;

    figure {
      text-align: center;
    }
  }

  .blocDroit {
    margin-left: 2vw;
  }

  h3 {
    margin-bottom: 0;
    color: #f68c59;

    span {
      font-weight: normal;
      cursor: pointer;
    }
  }

  .commentaire {
    font-size: 1.15rem;
    text-align: left;
  }

  .blocModal {
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    margin: 0;
    position: absolute;
    left: 0px;
    top: 0;
    text-align: center;

    .close {
      color: rgba(255, 255, 255, 0.4);
      font-size: 1.6rem;
      font-weight: bold;
      position: relative;
      left: 96vw;
      bottom: 71vh;
      cursor: pointer;
      width: 2vw;
      text-align: center;
    }

    h2 {
      text-align: center;
    }

    figure {
      display: inline-block;

      img {
        width: 30vw;
        margin: 0 7vw;
      }
    }
  }

  .hidden {
    display: none;
  }
}
</style>
<template>
  <div class="pages page10">
    <div class="titre">
      <h1>L’alcool et la conduite</h1>
      <h2>Une situation préoccupante</h2>
    </div>
    <p class="chiffre">652</p>
    <p class="commentaire">
      C’est le nombre de vies qui auraient pu etre sauvées en 2021...<br />
      ... <span>si</span> aucun conducteur n’avait conduit avec un taux d’alcool
      positif
    </p>
    <p class="basPage">Source ONISR 2021</p>
  </div>
</template>

<script>
export default {
  name: "Page10",
};
</script>

<style lang="scss" scoped>
.page10 {
  .chiffre {
    margin: 0 44%;
  }

  .commentaire {
    line-height: 4rem;
    text-transform: uppercase;
  }

  p {
    span {
      color: #f68c59;
    }
  }
}
</style>
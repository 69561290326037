<template>
  <div class="pages page60">
    <div class="titre">
      <h1>Les gants</h1>
    </div>
    <div class="blocGauche">
      <p>
        Il est important de bien différencier les gants des autres Équipements
        de Protection Individuelle (EPI).
      </p>
      <p>
        En effet depuis le <strong>20 novembre 2016</strong>, les gants,
        homologués pour la conduite ou la pratique d’un 2RM, sont devenus
        <strong
          >obligatoires pour tous les conducteurs et passagers de 2RM</strong
        >.
      </p>
      <p>
        Tout contrevenant risque un retrait d'<strong>1 point</strong> sur son
        permis (sauf cyclomoteur et passager), <strong
          >une amende de 3<sup>e</sup> classe de 68 €.</strong
        >
      </p>
      <aside>
        <p><img src="@/assets/gants-norme.jpg" alt="" /></p>
        <p>ou</p>
        <p>
          <strong>EN 13594</strong><br />Niveau 1 en milieu urbain<br>Niveau 2 pour
          conduite routière
        </p>
        <p>ou</p>
        <p>
          <strong>EN 13594 : 2003<br />EN 13594 : 2015</strong>
        </p>
        <p>ou</p>
        <p><strong>KP</strong></p>
      </aside>
    </div>
    <div class="blocDroit">
      <img src="@/assets/gants.jpg" alt="" />
      <p>
        Seule la mention de la norme est obligatoire, la présence du pictogramme
        est optionnelle.
      </p>
    </div>
    <p class="sousMenu">Obligatoire</p>
  </div>
</template>

<script>
export default {
  name: "Page60",
};
</script>

<style lang="scss" scoped>
.page60 {
  .blocGauche {
    width: 48vw;

    aside {
      img {
        width: 8vw;
      }
      p {
        width: 9vw;
        display: inline-block;
        vertical-align: middle;

        &:nth-child(even) {
          width: 2.2vw;
          color: #f68c59;
          font-weight: bold;
          font-size: 1.8rem;
          padding: 0 1vw;
          margin: 0;
        }

        &:nth-child(odd) {
            text-align: center;
        }

        &:last-child {
            width: 2.4vw;
        }
      }
    }
  }
  .blocDroit {
    width: 48vw;
    height: 92%;
    background: linear-gradient(to right, transparent 27%, #f68c59 27%);
    position: absolute;
    top: 0;
    right: 0;

    img {
      width: 45vw;
      margin-top: 5vh;
    }

    p {
      padding-right: 3vw;
      padding-left: 14vw;
      margin: 0;
      color: white;
    }
  }

  .sousMenu {
    bottom: 67vh;
  }
}
</style>
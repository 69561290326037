<template>
  <div class="pages page52">
    <div class="titre">
      <h1>Anticipation</h1>
      <h2>testons nous !</h2>
    </div>
    <div class="blocTexte">
      <p class="commentaire">
        Une photo va apparaître durant
        <span class="texteOrange">3 secondes</span>,<br />soit
        <span class="texteOrange">3 fois</span> le temps nécessaire pour
        réagir...
      </p>
      <button @click="startAnimation()">Lancer l’animation</button>
      <p>( Le temps de réaction normal est d'environ 1 seconde )</p>
      <button id="reponse" class="hidden" @click="showModal($event, '#modal2')">
        <svg viewBox="0 0 100 100">
          <path
            d="M37.3,13.8c-1.5-0.7-3.1-1-4.6-1.4c-1.6,0.4-3,0.6-4.2,1.1c-1.6,0.6-2.9,1.8-3.3,3.6c-0.3,1.2,0.2,2.1,1.2,2.6
	c1,0.5,1.9,0.3,2.5-0.8c1-1.6,2.4-2,4.1-1.8c1.1,0.1,2.2,0.5,2.6,1.6c0.5,1.2,0.1,2.2-0.7,3c-0.7,0.8-1.6,1.4-2.4,2.1
	c-2.1,1.8-2.8,4.1-2.5,6.8c0.2,1.2,0.8,1.6,2.3,1.5c1.4-0.1,2-0.6,1.9-1.7c-0.1-2,0.8-3.4,2.3-4.7c1.3-1.1,2.5-2.5,3.3-4
	C41.5,18.6,40.4,15.3,37.3,13.8z M81.9,57.4c-1.9,0-3.5,1.5-3.5,3.4c0,2,1.5,3.6,3.4,3.6c1.9,0,3.6-1.6,3.6-3.5
	C85.5,59.1,83.9,57.5,81.9,57.4z M32.1,33.8c-1.4,0-2.5,1-2.5,2.4c0,1.4,1.1,2.6,2.4,2.6c1.4,0,2.6-1.1,2.6-2.5
	C34.6,35,33.5,33.8,32.1,33.8z M99.5,48.1c0-0.8,0-1.6-0.1-2.4c-1.1-6.2-6.1-10.4-12.4-10.5c-6.9,0-13.8,0-20.6,0
	c-0.9,0-1.1-0.2-1.1-1.1c0-7.1,0-14.2,0-21.2c0-0.8,0-1.7-0.1-2.5C63.9,4.3,59,0.1,52.6,0C46,0,39.4,0,32.9,0c-6.5,0-13.1,0-19.6,0
	C5.9,0,0.5,5.4,0.5,12.7c0,8.7,0,17.3,0,26c0,7.2,5.2,12.5,12.4,12.7c0.7,0,0.9,0.3,0.9,0.9c0,2.1,0,4.1,0,6.2c0,0.7,0,1.4,0.2,2.1
	c1.2,4.2,6.3,5.6,9.5,2.5c3.5-3.4,7-6.9,10.5-10.4c0.2-0.2,0.5-0.3,0.7-0.5c0,0.3,0.1,0.6,0.1,0.8c0,6.9,0,13.8,0,20.7
	c0,0.8,0,1.7,0.1,2.5C36,82.4,41,86.6,47.3,86.7c5.5,0,11,0,16.4,0c0.7,0,1.2,0.2,1.7,0.7c3.5,3.6,7.1,7.1,10.7,10.6
	c2,2,4.3,2.5,6.5,1.5c2.5-1.1,3.5-3.1,3.5-5.7c0-2.1,0-4.2,0-6.3c0-0.6,0.1-0.9,0.8-0.9c7.3,0,12.7-6,12.5-12.9
	C99.3,65.2,99.5,56.6,99.5,48.1z M34.9,46.6c-1.1,0-2,0.4-2.7,1.1c-3.8,3.9-7.7,7.7-11.6,11.5c-0.3,0.3-0.9,0.4-1.3,0.6
	c-0.2-0.5-0.6-1-0.6-1.4c-0.1-3,0-5.9,0-8.9c0-2.1-0.9-2.9-3-2.9c-1.4,0-2.9,0.1-4.3-0.2c-3.4-0.6-6-3.6-6-7.1
	c-0.1-9.1-0.1-18.1,0-27.2c0-4.3,3.4-7.3,7.9-7.4c6.5,0,13.1,0,19.6,0c6.5,0,13,0,19.5,0c4,0,7.1,2.4,7.8,6.1c0.1,0.6,0.1,1.3,0.1,2
	c0,8.5,0,17.1,0,25.6c0,4.9-3.2,8.1-8.1,8.1C46.5,46.6,40.7,46.6,34.9,46.6z M94.7,73.8c0,4.9-3.2,8.1-8.1,8.1c-0.9,0-1.7,0-2.6,0
	c-1.7,0-2.6,0.9-2.6,2.6c0,3,0,5.9,0,8.9c0,0.3,0.1,0.6,0,0.9c-0.1,0.3-0.3,0.7-0.6,0.8c-0.3,0.1-0.7-0.1-1-0.2
	c-0.2-0.1-0.3-0.3-0.5-0.5c-3.7-3.7-7.5-7.4-11.2-11.2c-0.9-0.9-1.9-1.3-3.3-1.3c-5.8,0.1-11.6,0-17.4,0c-3.8,0-6.9-2.4-7.6-6
	c-0.1-0.6-0.2-1.3-0.2-2c0-7.1,0-14.3,0-21.4c0-0.8,0.2-1.1,1-1.1c4,0,8,0,12,0c6.4,0,11.3-4.2,12.5-10.5c0.1-0.7,0.4-0.9,1.1-0.9
	c6.9,0,13.7,0,20.6,0c4.7,0,8,3.2,8,7.9c0,4.4,0,8.7,0,13.1C94.7,65.3,94.7,69.5,94.7,73.8z M52.3,57.4c-1.9,0-3.5,1.6-3.5,3.5
	c0,1.9,1.5,3.6,3.5,3.6c1.9,0,3.5-1.6,3.6-3.5C55.8,59,54.2,57.4,52.3,57.4z M67.1,57.4c-1.9,0-3.5,1.6-3.5,3.5
	c0,1.9,1.6,3.6,3.5,3.6c1.9,0,3.6-1.6,3.6-3.5C70.7,59.1,69,57.4,67.1,57.4z"
          />
        </svg>
      </button>
    </div>
    <div id="modal1" class="blocModal hidden">
      <img src="@/assets/anticipation-1.jpg" alt="" />
    </div>
    <div id="modal2" class="blocModal hidden">
      <svg
        version="1.1"
        id="Calque_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 307.2 177.9"
        style="enable-background: new 0 0 307.2 177.9"
        xml:space="preserve"
      >
        <image
          style="overflow: visible"
          width="1024"
          height="593"
          xlink:href="@/assets/anticipation-1.jpg"
          transform="matrix(0.3 0 0 0.3 0 0)"
        ></image>
        <path
          @click="switchModal($event, '#modal5')"
          style="fill: #ffffff; fill-opacity: 0.1; stroke: none"
          d="M49.8,103.7c0,0,14.9-1.3,17.2,8.6s1.5,10.2,1.5,10.2
	s0.7,10.6-6.4,12.7c-7.1,2.1-27.7,5.6-30.4,5.4c-2.6-0.2-18.4-0.1-20.8-12.1c-2.1-10.4-0.6-18.3,9.8-20.1
	C30.9,106.6,49.8,103.7,49.8,103.7z"
        />
        <path
          @click="switchModal($event, '#modal5')"
          style="fill: #ffffff; fill-opacity: 0.1; stroke: none"
          d="M288.2,103.3c0,0-14.7,1.7-15.4,12.4s0,11.2,0,11.2
	s2.6,8.6,12.6,9.9c9.9,1.3,21.8,1.3,21.8,1.3v-34.8C307.2,103.3,292.8,102.3,288.2,103.3z"
        />
        <path
          @click="switchModal($event, '#modal4')"
          style="fill: #ffffff; fill-opacity: 0.1; stroke: none"
          d="M174.1,22.6c0,0-3.5-0.5-5.9,7.8c-2.5,8.3-5.9,14.9-5.9,22.6
	s7.9,5.9,7.9,5.9l52.8,0.2c0,0,1.2-16.5-1.7-18.3c-2.8-1.7-2.8-1.7-4-3.7c-1.2-2-4.6-12.4-6.9-13.4s-19.3-2-19.3-2L174.1,22.6z"
        />
      </svg>
      <p class="close" @click="closeModal($event)">X</p>
    </div>
    <div id="modal3" class="blocModal hidden">
      <svg
        version="1.1"
        id="Calque_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 307.2 177.9"
        style="enable-background: new 0 0 307.2 177.9"
        xml:space="preserve"
      >
        <image
          style="overflow: visible"
          width="1024"
          height="593"
          xlink:href="@/assets/anticipation-2.jpg"
          transform="matrix(0.3 0 0 0.3 0 0)"
        ></image>
        <path
          @click="switchModal($event, '#modal4')"
          style="fill: #ffffff; fill-opacity: 0.1; stroke: none"
          d="M205.7,48.8c-1.9,2.4-6.3,10.4-6.3,10.4s-1.7,0.7-2.8,1.8
	s-2.6,13-2.1,17c0.5,4-0.2,7.9,1.2,9.1c1.3,1.2,11.9,14.7,11.9,14.7l60.8-1.2c0,0,4-0.7,4.8-6.8c0.8-6.1,3.2-16.6-4.3-25.3
	c-4.1-4.8-6.9-1.6-9.1-4.1c-1.2-1.3,3-0.7,3.3-2.6s-3.3-2.6-3.3-2.6s-1.8,0-2.8,1.7c-1,1.7-5.4-10.2-10.2-12.1
	c-4.8-1.8-8.7-1.8-12.5-2c-3.8-0.2-18.5,0.5-21.2,0.8C210.3,48,208.3,45.5,205.7,48.8z"
        />
      </svg>
      <p class="close" @click="closeModal($event)">X</p>
    </div>
    <div id="modal4" class="blocModal hidden">
      <svg
        version="1.1"
        id="Calque_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 307.2 177.9"
        style="enable-background: new 0 0 307.2 177.9"
        xml:space="preserve"
      >
        <image
          style="overflow: visible"
          width="1024"
          height="593"
          xlink:href="@/assets/anticipation-3.jpg"
          transform="matrix(0.3 0 0 0.3 0 0)"
        ></image>
        <path
          @click="switchModal($event, '#modal2')"
          style="fill: #ffffff; fill-opacity: 0.1; stroke: none"
          d="M239,0c0,0-17.8,5.9-16.8,16.8c1,10.9,16.5,16.2,25.4,15.9
	c8.9-0.3,42.6-1.7,42.6-1.7s17-1.7,17-2.3s0-28.7,0-28.7H239z"
        />
        <path
          @click="switchModal($event, '#modal2')"
          style="fill: #ffffff; fill-opacity: 0.1; stroke: none"
          d="M0,94.1c0,0,9.8-5.3,19.7-5.1c5.4,0.1,25,0.4,29.4,13.4
	c1.6,4.6,7.6,8.3,7.6,8.3L6.2,125.1c0,0-5.8,1.1-6.2-1.7C0,116.2,0,94.1,0,94.1z"
        />
        <path
          @click="switchModal($event, '#modal3')"
          style="fill: #ffffff; fill-opacity: 0.1; stroke: none"
          d="M276.7,40.9c0,0-2.6,1.7-3,3.3c-0.3,1.7,1.5,4.3,1.5,4.3l-1.5,3
	l-5.2,17.8l2,2.6l2.1,17h17.7l-6.6-14.7c0,0,3.6-9.6,3.6-16.2s0.7-7.6-4.3-10.6C278,44.6,283.6,40.9,276.7,40.9z"
        />
      </svg>
      <p class="close" @click="closeModal($event)">X</p>
    </div>
    <div id="modal5" class="blocModal hidden">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 307.2 177.9"
        style="enable-background: new 0 0 307.2 177.9"
        xml:space="preserve"
      >
        <image
          style="overflow: visible"
          width="1024"
          height="593"
          xlink:href="@/assets/anticipation-4.jpg"
          transform="matrix(0.3 0 0 0.3 0 0)"
        ></image>
        <path
          @click="switchModal($event, '#modal3')"
          style="fill: #ffffff; fill-opacity: 0.1; stroke: none"
          d="M219.5,26.5c1.5,0.5,1.8,1.9,3.1,3.7c1.8,2.6,2.8,2.3,3.7,4
	c1.4,2.4,0.2,4.5-0.4,8.8c-0.2,1.2-1.1,7.4,0.2,13.2c1.5,6.5,4.8,8.1,4,10.3c-2,5.1-19,3.5-19.2,2c0-0.5,1.7-0.4,3.3-2
	c2.8-2.8,3-8.2,1.5-12.1c-0.8-2.2-1.6-2.4-1.8-4.2c-0.3-2.5,1-3.4,2.2-6.6c0.6-1.6,2.5-6.7,1.3-12.1c-0.5-2.1-1.3-4-0.4-4.8
	C217.7,26.1,218.8,26.3,219.5,26.5z"
        />
        <path
          @click="switchModal($event, '#modal4')"
          style="fill: #ffffff; fill-opacity: 0.1; stroke: none"
          d="M173.7,30.7c-15.2,0-18.2-0.2-20.9,1.3c-3.2,3.5-0.7,8.1-5.3,13.7
	c-2.1,2.6-7.9-1-8.8,2.5c-1.3,5,4.9-0.7,4.6,5.4c-0.4,7.7-5.5,28.9-1.7,35.4c22.9,1,40.9,1,66.5,1.4c-0.2-10.8,2.9-29.8-2.4-39
	c-2-3.5,4.2-2.5,0.9-4.4c-1.1-3-4.1,0.9-5.1-2.2c-1.5-5.1-6.4-11.3-9-12.8C188.9,30.9,180.3,30.7,173.7,30.7z"
        />
        <path
          @click="switchModal($event, '#modal2')"
          style="fill: #ffffff; fill-opacity: 0.1; stroke: none"
          d="M56.8,15.9c0,0-12.3,3.1-12.1,12.6c0.2,9.5,3.7,12.1,3.7,12.1
	s-8.8,2.9-11,4c-2.2,1.1-1.3,11.4-3.1,12.6c-1.8,1.1-5.7,5.5-5.1,9c0.7,3.5,6.8,7.5,9.2,7c2.4-0.4-2.6,3.3-2.4,5.5S28,81.3,28,81.3
	v1.8l4.6,19.8l-1.3,6.2h-4.6l-5.3,1.3l5.3,2.4l4.8-1.1l-3.7,17.4l0.9,5.3l3.3,26.2c0,0,2.6,11.7,7.2,11s13.3-0.7,14.2-2.6
	c0.9-2,5.7-14.1,5.3-17.6c-0.4-3.5,8.4-0.9,11.2-5.1c2.9-4.2,5.7-0.4,6.8-3.7c1.1-3.3,2.6-5.3,6.8-6.4c4.2-1.1-1.5-5.9-2.4-7.7
	c-0.9-1.8,9.7-23.3,8.4-34.7s2.2-2.3,2.2-2.3l5.1-1.1l-4.8-1.4l-2.6-2.6l-1.1-4.8c0,0-1.3-3.3,0.7-5.9c2-2.6,4.4,2.6,7.9,1.1
	c3.5-1.5-6.2-12.3-6.2-12.3l3.7-2.9l7.5-1c0,0,2.6,0.6,2.4-3c-0.2-3.5-3.7-4-3.7-4L95.8,54c0,0-3.3,0.4-2.9,2.9c0.4,2.4,0,3.6,0,3.6
	L90.1,63l-4.4-2.5l-2.9-3.8L79.7,47c-10.6-5.3-13-6.4-13-6.4s3.7-6,3.3-9.9C69.6,26.7,66.1,16.4,56.8,15.9z"
        />
      </svg>
      <p class="close" @click="closeModal($event)">X</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page52",

  data() {
    return {
      intervalID: null,
    };
  },

  methods: {
    startAnimation: function () {
      let modal = document.querySelector("#modal1");

      modal.classList.remove("hidden");
      this.intervalID = setInterval(this.stopAnimation, 3000);
    },

    stopAnimation: function () {
      let modal = document.querySelector("#modal1");
      let reponse = document.querySelector("#reponse");

      modal.classList.add("hidden");
      reponse.classList.remove("hidden");
      clearInterval(this.intervalID);
    },

    showModal: function (e, id) {
      let modal = document.querySelector(id);
      modal.classList.remove("hidden");
    },

    switchModal: function (e, id) {
      let old = e.target.closest("div");
      old.classList.add("hidden");

      let modal = document.querySelector(id);
      modal.classList.remove("hidden");
    },

    closeModal: function (e) {
      e.target.parentElement.classList.add("hidden");
    },
  },
};
</script>

<style lang="scss" scoped>
.page52 {
  .blocTexte {
    .commentaire {
      line-height: 3rem;
    }

    p {
      text-align: center;
    }
  }

  button {
    color: white;
    background-color: #f68c59;
    margin: 5vh 37%;
    width: 24vw;
    height: 10vh;
    font-size: 2rem;
    font-weight: bold;
    border: 0;
    cursor: pointer;
  }

  #reponse {
    fill: #034ea2;
    background-color: transparent;
    position: relative;
    bottom: 18vh;
    left: 45vw;
    width: 10vw;
  }

  .blocModal {
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    margin: 0;
    position: absolute;
    left: 0px;
    top: 0;

    img {
      width: 66vw;
      margin: 3vh 17vw;
    }

    svg {
      width: 66vw;
      margin: 2vh 17vw;
    }

    path {
      cursor: pointer;
    }

    .close {
      position: relative;
      bottom: 75vh;
      left: 97vw;
      color: white;
      font-size: 2rem;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .hidden {
    display: none;
  }
}
</style>
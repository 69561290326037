<template>
  <div class="pages page64">
    <div class="titre">
      <h1>Blouson</h1>
    </div>
    <div class="blocGauche">
      <ul>
        <li>
          Il doit comporter des <strong>couleurs vives</strong> et des
          <strong>dispositifs rétroréfléchissants</strong> pour une meilleure
          visibilité par les autres usagers, de jour comme de nuit,
        </li>
        <li>
          Être assez large pour rester <strong>confortable</strong>, mais assez
          serré pour <strong>protéger des chocs</strong>,
        </li>
        <li>
          Être <strong>serré au niveau des poignets</strong> pour éviter que les
          manches ne remontent,
        </li>
        <li>
          Pouvoir <strong>s’adapter</strong> au-dessus d’autres vêtements,
        </li>
        <li>
          Être équipé de <strong>protections aux coudes</strong> et
          <strong>aux épaules</strong>.
        </li>
        <li>
          Il est possible d’acquérir des protections dorsales et ventrales
          indépendamment du blouson. Ces protections peuvent être installées de
          manière autonome, par exemple à l’aide de bretelles.
        </li>
      </ul>
    </div>
    <div class="blocDroit">
      <aside>
        <img src="@/assets/gants-norme.jpg" alt="" />
        <p>Norme<br />EN 13595</p>
      </aside>
    </div>
    <figure><img src="@/assets/veste.png" alt="" /></figure>
    <p class="sousMenu">Conseillés</p>
  </div>
</template>

<script>
export default {
  name: "Page64",
};
</script>

<style lang="scss" scoped>
.page64 {
  .blocGauche {
    width: 45vw;

    li {
      margin-top: 2vh;
    }
  }

  .blocDroit {
    background-color: #f68c59;
    width: 32vw;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;

    aside {
      padding-left: 16vw;
      padding-top: 10vh;
      color: white;

      img {
        width: 8vw;
      }
    }
  }

  figure {
    position: absolute;
    top: 0px;
    left: 49vw;

    img {
      width: 33vw;
    }
  }

  .sousMenu {
    bottom: 60vh;
  }
}
</style>
<template>
  <div class="pages page55">
    <div class="titre">
      <h1>Zones de non visibilité</h1>
      <h2>... et porte-à-faux</h2>
    </div>
    <div class="blocGauche">
      <p>
        Anticiper le comportement routier d’un véhicule en prenant en compte son
        changement de trajectoire (le porte-à-faux balaye le côté opposé au
        changement de direction = le véhicule peut se déporter de sa voie).
      </p>
      <p>
        Ne jamais se mettre entre le mobilier urbain et un véhicule qui
        s’apprête à changer de direction.
      </p>
      <p>
        Prendre toutes les précautions utiles et anticiper lors d’un dépassement
        (véhicule venant en face, masqué par le véhicule qui manœuvre).
      </p>
    </div>
    <figure><img src="@/assets/porte-a-faux.jpg" alt="" /></figure>
  </div>
</template>

<script>
export default {
  name: "Page55",
};
</script>

<style lang="scss" scoped>
.page55 {
  .blocGauche {
    width: 45vw;
  }
  figure {
    position: absolute;
    top: 12vh;
    right: 4vw;

    img {
      width: 37vw;
      display: block;
      margin: 3vh 0;
    }
  }
}
</style>
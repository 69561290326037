<template>
  <div id="nav">
    <img
      id="logo"
      src="@/assets/logo_gmf.svg"
      alt="Logo de la GMF"
      @click="goto('home')"
    />
    <img
      id="previous"
      src="@/assets/prev.svg"
      alt="Flèche précedente"
      @click="goto(appState[$route.params.id_page - 1].previousPage)"
    />
    <img
      id="next"
      src="@/assets/next.svg"
      alt="Flèche suivante"
      @click="goto(appState[$route.params.id_page - 1].nextPage)"
    />
  </div>
</template>

<script>
export default {
  name: "Nav",

  methods: {
    goto: function (page) {
      var pageURL = "";

      if (page == "home") {
        this.$router.push({ path: "/" });
      } else if (page == "credit") {
        console.log("credit");
        this.credits = true;
        this.$emit("credit-update", true);
        //console.log (this);
      } else if (page == "end") {
        console.log(page);
      } else {
        pageURL = "/page/" + page;
        this.$router.push({ path: pageURL });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#nav {
  position: absolute;
  bottom: 0px;
  background-color: white;
  width: 100%;
  height: 14vh;

  img {
    cursor: pointer;
  }

  #logo {
    height: 8vw;
    float: left;
  }

  #previous {
    position: absolute;
    left: 80vh;
    top: 25%;
    height: 45px;
  }

  #next {
    position: absolute;
    left: 88vh;
    top: 25%;
    height: 45px;
  }

  .end {
    opacity: 0.2;
    cursor: not-allowed;
  }
}
</style>

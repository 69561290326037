<template>
  <div class="pages page21">
    <div class="titre">
      <h1>Vitesse et choc à l’impact</h1>
    </div>
    <p>À une vitesse de 25 km/h, le choc ressenti à l'impact est équivalent à une chute de 2,50 m.</p>
    <figure><img src="../assets/vitesse-21-chute.png" alt="" /></figure>
  </div>
</template>

<script>
export default {
  name: "Page21",
};
</script>

<style lang="scss" scoped>
.page21 {

  p {
    width: 28vw;
  }
  figure {
    left: 12vw;
    position: relative;
    bottom: 23vh;

    img {
        width: 80vw;
    }
  }
}
</style>
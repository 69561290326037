<template>
  <div class="pages page57">
    <div class="titre">
      <h1>Le casque :</h1>
      <h2>Il existe 3 types de casque</h2>
    </div>
    <div class="blocGauche">
      <img src="@/assets/casque_enveloppant.png" alt="" />
      <p><strong>Casque enveloppant</strong><em>(Norme J)</em></p>
      <p>Ils protègent la tête et la nuque, mais pas le visage et le menton.</p>
    </div>
    <div class="blocCentre">
      <img src="@/assets/casque_integral.png" alt="" />
      <p><strong>Casque intégral</strong><em> (Norme P)</em></p>
      <p>
        Ils enveloppent complètement la tête, mais sont plus lourds que les
        enveloppants.
      </p>
    </div>
    <div class="blocDroite">
      <img src="@/assets/casque_modulaire.png" alt="" />
      <p><strong>Casque modulaire</strong><em> (Norme PJ)</em></p>
      <p>
        Ils combinent les avantages des deux autres mais sont plus lourds et
        plus encombrants. La partie avant, la mentonnière, s’escamote afin de
        dégager le visage du pilote.
      </p>
    </div>
    <p class="sousMenu">Obligatoire</p>
  </div>
</template>

<script>
export default {
  name: "Page57",
};
</script>

<style lang="scss" scoped>
.page57 {
  .blocGauche,
  .blocCentre,
  .blocDroite {
    display: inline-block;
    width: 29vw;
    padding: 0 1vw;
    overflow: hidden;
    height: 48vh;
    text-align: center;

    p:first-of-type {
      margin-top: 0;
    }
  }

  img {
    width: 64%;
  }

  p {
    text-align: center;
  }

  .sousMenu {
    bottom: 73vh;
  }
}
</style>
<template>
  <div class="pages page35">
    <div class="blocCentral">
      <camembert dataFile="urbain-2rm.json" titre="Le 2RM et le milieu urbain"></camembert>
      <!--<camembert dataFile="urbain-grand_paris.json" titre="Une singularité : Le Grand Paris"></camembert>-->
      <p class="basPage">Source ONISR 2022</p>
    </div>
  </div>
</template>

<script>
import Camembert from '@/components/camembert.vue';

export default {
  name: "Page35",

  components: {
    Camembert,
  },

  
};
</script>

<style lang="scss" scoped>
.page35 {

  .blocCentral {
    //text-align: center;
  }

  .pie {
    //width: 35vw;
    //display: inline-block;
    //margin: 0 5vw;
    width: 100%;
    vertical-align: top;
}

  .percent {
    bottom: 12vh;
  }
}
</style>
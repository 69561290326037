<template>
  <div class="pages page61">
    <div class="titre">
      <h1>Le gilet haute visibilité</h1>
    </div>
    <div class="blocGauche">
      <p>
        Depuis le 1er janvier 2015, l’obligation de détenir un gilet de haute
        visibilité a été étendue aux 2RM.
      </p>
      <p>
        Ils sont par conséquent tenus de le présenter en cas de contrôle routier
        et de le porter en cas d'immobilisation du véhicule sur la chaussée ou
        ses abords.
      </p>
      <p>
        Les contrevenants encourreront une amende de <strong>11 €</strong> en cas d'absence de
        gilet à proximité, de <strong>135 €</strong> s'ils ne le portent pas à la suite d'un
        arrêt d'urgence.
      </p>
      <aside>
        <p><img src="@/assets/gillet-norme.svg" alt="" /></p>
        <p><strong>Norme<br />(CE) EN 471<br />ou<br />(CE) EN 1150</strong></p>
      </aside>
      <figure>
        <img src="@/assets/gillet.png" alt="" />
      </figure>
    </div>
    <p class="sousMenu">Obligatoire</p>
  </div>
</template>

<script>
export default {
  name: "Page61",
};
</script>

<style lang="scss" scoped>
.page61 {
  .blocGauche {
    width: 45vw;

    aside {
        p {
            display: inline-block;
            vertical-align: middle;
            padding: 0 .8vw;
        }
      img {
        width: 7vw;
      }
    }
  }
  figure {
    img {
      position: absolute;
      top: 0;
      right: 3vw;
      width: 38vw;
    }
  }

  .sousMenu {
    bottom: 61vh;
  }
}
</style>
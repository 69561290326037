<template>
  <div class="pages page58">
    <div class="titre">
      <h1>Casque et autocollants :</h1>
      <h2>Rétro réfléchissants</h2>
    </div>
    <p>
      Pour une meilleure visibilité, le code de la route via
      <strong>l'article R 431-1</strong> prévoit la mise en place obligatoire de
      <strong> 4 autocollants rétro réfléchissants </strong> pour l'homologation
      <strong>
        des casques de protection et de leurs écrans pour conducteurs et
        passagers de motocyclettes et de cyclomoteurs.
      </strong>
      Ceux-ci doivent être placés aux endroits préconisés sous peine d'une
      amende de 4<sup>e</sup> classe pour casque non homologué :
      <strong>135 € et 3 points !</strong>
    </p>
    <div class="bloc">
      <img src="@/assets/casque-front.png" alt="" />
      <p>Front / menton</p>
    </div>
    <div class="bloc">
      <img src="@/assets/casque-arriere.png" alt="" />
      <p>Arrière - visuel</p>
    </div>
    <div class="bloc">
      <img src="@/assets/casque-cotes.png" alt="" />
      <p>Côtés - visuel</p>
    </div>
    <p class="sousMenu">Obligatoire</p>
  </div>
</template>

<script>
export default {
  name: "Page58",
};
</script>

<style lang="scss" scoped>
.page58 {
  .bloc {
    display: inline-block;
    width: 29vw;
    padding: 0 1vw;
    overflow: hidden;
    height: 48vh;
    text-align: center;

    img {
      width: 64%;
    }

    p {
      text-align: center;
    }
  }

  .sousMenu {
    bottom: 83vh;
  }
}
</style>
<template>
  <div class="pages page31">
    <div class="titre">
        <h1>Dépassement sur la droite</h1>
    </div>
    <div class="blocGauche">
      <miniPlayer
        url="https://player.vimeo.com/progressive_redirect/playback/828952106/rendition/1080p/file.mp4?loc=external&signature=324a993f9b47a8fbfff667a3256643e7c56bc56e99a4f4533074e661d546cc87"
        thumbnail="thumbnail-depassement-droite.jpg"
      ></miniPlayer>
    </div>
    <div class="blocDroit">
      <img src="@/assets/depassement-droite.jpg" alt="" />
    </div>
    <div class="blocTexte">
      <p>
        Un véhicule de tourisme déboite sans signalement et coupe la trajectoire du 2RM s'apprêtant à doubler sur sa droite.
      </p>
    </div>
  </div>
</template>

<script>
import miniPlayer from "@/components/miniPlayer.vue";

export default {
  name: "Page31",

  components: {
    miniPlayer,
  },
};
</script>


<style lang="scss">
.page31 {
  .blocGauche,
  .blocDroit {
    display: inline-block;
    width: 40vw;
    padding: 0 1vw;
    overflow: hidden;
    height: 43vh;
    margin: 0 2.4vw;
  }

  .blocDroit {
    img {
      width: 40vw;
    }
  }

  .blocTexte {
    p {
        text-align: center;
    }
  }
}
</style>
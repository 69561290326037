<template>
  <div class="pages page23">
    <div class="titre">
      <h1>Vitesse et énergie cinétique</h1>
    </div>
    <div class="blocCentral">
      <p>
        Voici quelques exemples de distances d’arrêt pour un véhicule
        normalement entretenu ...
      </p>
      <figure>
        <img src="@/assets/vitesse-23-distance_arret.png" alt="" />
      </figure>
    </div>
    <div class="blocGauche">
      <ul>
        <li>
          <img src="@/assets/vitesse-23-fleche-noir.png" alt="" /><span
            >Distance parcourue pendant le temps de réaction (1 seconde)</span
          >
        </li>
        <li>
          <img src="@/assets/vitesse-23-fleche-bleu.png" alt="" /><span
            >Distance de freinage sur route sèche</span
          >
        </li>
        <li>
          <img src="@/assets/vitesse-23-fleche-orange.png" alt="" /><span
            >Distance de freinage sur route mouillée</span
          >
        </li>
      </ul>
    </div>
    <div class="blocDroit">
      <p class="texteOrange">
        Il est souvent moins dangereux pour un 2RM d'éviter un obstacle que de
        freiner.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page23",
};
</script>

<style lang="scss" scoped>
.page23 {
  .blocCentral {
    figure {
      text-align: center;
      margin:0;
    }
    img {
      width: 67vw;
    }
  }
  .blocGauche,
  .blocDroit {
    display: inline-block;
  }

  .blocGauche {
    ul {
      li {
        list-style-type: none;
      }
    }
    img {
      width: 2vw;
      margin-right: 1vw;
    }
  }

  .blocDroit {
    margin-left: 12vw;
    width: 29vw;
    vertical-align: top;
  }
}
</style>
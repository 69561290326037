<template>
  <div class="pages page47">
    <div class="titre">
      <h1>Le danger,</h1>
      <h2>c’est les autres</h2>
    </div>
    <div class="blocGauche">
      <p>
        Selon le 12<sup>ème</sup> baromètre de la conduite responsable de la Fondation Vinci,
        les Français se considèrent vigilants à 74 % et calmes à 54 %. Ils jugent
        à 43 % les autres conducteurs comme irresponsables. Cette tendance
        coexiste avec un sentiment d'insécurité puisque 88 % ont déjà eu peur du
        comportement agressif d'un autre conducteur.
      </p>
      <p>
        Mais les comportements à risque sont en hausse car 57 % déclarent oublier
        de mettre leur clignotant quand nécessaire et 14 % avouent conduire
        au-dessus de la limite d'alcool autorisée.
      </p>
    </div>
    <div class="blocDroit">
      <figure><img src="../assets/regle-moto_glisse.jpg" alt="" /></figure>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page47",
};
</script>

<style lang="scss" scoped>
.page47 {
  .blocGauche {
    width: 40vw;
  }

  .blocDroit {
    position: absolute;
    top: 0vh;
    right: 0vw;
    background: linear-gradient(to right, transparent 21%, #f68c59 21%);
    width: 45vw;
    height: 100%;
    margin: 0;

    img {
      width: 28vw;
      display: block;
      margin-top: 5vh;
    }
  }
}
</style>
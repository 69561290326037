<template>
  <div class="pages page59">
    <div class="titre">
      <h1>Composition d’un casque :</h1>
      <h2>Enveloppant, intégral ou modulaire</h2>
    </div>
    <div class="texte">
      <p>
        un casque est composé : d’une calotte extérieure en matériaux résistant
        pour répartir le choc, d’un calotin amortisseur en polystyrène pour
        absorber l’énergie résiduelle du choc, d’une garniture intérieure pour
        le confort.
      </p>
      <div class="bloc">
        <ol>
          <li>
            <strong>Coque ou calotte :</strong> partie extérieure du casque, elle
            permet de répartir l’impact du choc par glissement sur le calotin.
          </li>
          <li>
            <strong>Calotin :</strong> situé sous la coque, il est destiné à
            absorber une grande partie des chocs par déformation.
          </li>
          <li>
            <strong>Estampille :</strong> atteste la conformité du casque aux
            normes.
          </li>
          <li>
            <strong>Ecran Protection du visage :</strong> air, pluie, poussière.
          </li>
          <li>
            <strong>Aérateur maxilaire :</strong> permet l’aération par
            circulation d’air.
          </li>
          <li>
            <strong>Rembourrage :</strong> matière en contact avec le crâne,
            confort et chaleur.
          </li>
          <li><strong>Jugulaire :</strong> maintien le casque sous le menton.</li>
          <li>
            <strong>Protège nuque :</strong> protection des vertèbres cervicales.
          </li>
          <li>
            <strong>Boucle de fermeture :</strong> fermeture de la jugulaire.
          </li>
          <li>
            <strong>Peinture extérieure :</strong> esthétique, surface de
            personnalisation, protection contre les ultra-violets et les rayures.
          </li>
        </ol>
      </div>
    </div>
    <img src="@/assets/casque-compo.svg" alt="" />
    <p class="sousMenu">Obligatoire</p>
  </div>
</template>

<script>
export default {
  name: "Page59",
};
</script>

<style lang="scss" scoped>
.page59 {
  .texte {
    width: 50vw;
  }

  p:first-of-type {
    margin-top: 0;
  }

  img {
    position: absolute;
    top: 1vw;
    width: 74vw;
    right: -11vw;
  }


  .sousMenu {
    bottom: 70vh;
  }
}
</style>
<template>
  <div class="pages page32">
    <div class="titre">
        <h1>Distance de sécurité</h1>
    </div>
    <div class="blocGauche">
      <miniPlayer
        url="https://player.vimeo.com/progressive_redirect/playback/828952141/rendition/1080p/file.mp4?loc=external&signature=5654346f23d40fa76f1ee7781766975c8bfc73f596a531a4409266108e092545"
        thumbnail="thumbnail-distance-securite.jpg"
      ></miniPlayer>
    </div>
    <div class="blocDroit">
      <img src="../assets/distance-securite.jpg" alt="" />
    </div>
    <div class="blocTexte">
      <p>
        Le 2RM ne respecte pas les distances de sécurité avec un véhicule de tourisme.
      </p>
    </div>
  </div>
</template>

<script>
import miniPlayer from "@/components/miniPlayer.vue";

export default {
  name: "Page32",

  components: {
    miniPlayer,
  },
};
</script>


<style lang="scss">
.page32 {
  .blocGauche,
  .blocDroit {
    display: inline-block;
    width: 40vw;
    padding: 0 1vw;
    overflow: hidden;
    height: 43vh;
    margin: 0 2.4vw;
  }

  .blocDroit {
    img {
      width: 40vw;
    }
  }

  .blocTexte {
    p {
        text-align: center;
    }
  }
}
</style>

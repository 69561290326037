<template>
  <div class="pages page26">
    <div class="titre">
      <h1>Vitesse et énergie cinétique</h1>
      <h2>Respectez les distances de sécurité</h2>
    </div>
    <div class="blocGauche">
      <p>
        La zone de contact du pneu d'un 2RM sur la route est
        équivalent à la surface d'une carte de crédit, <strong>
          il est
          important d'insister sur le bon respect des
          distances de sécurité
        </strong>.
      </p>
      <p>
        Pour calculer approximativement la distance de
        sécurité que je dois laisser avec le véhicule devant moi, je multiplie
        le chiffre des dizaines de km/h (vitesse à laquelle je roule) par 6.
      </p>
      <p class="calcul">
        Je circule à<img src="@/assets/vitesse50.png" alt="" />, je dois laisser
        5 x 6 = <span class="texteOrange">30 mètres</span>
      </p>
      <p class="calcul">
        Je circule à<img src="@/assets/vitesse90.png" alt="" />, je dois laisser
        9 x 6 = <span class="texteOrange">54 mètres</span>
      </p>
      <p class="calcul">
        Je circule à<img src="@/assets/vitesse110.png" alt="" />, je dois
        laisser 11 x 6 = <span class="texteOrange">66 mètres</span>
      </p>
      <p class="calcul">
        Je circule à<img src="@/assets/vitesse130.png" alt="" />, je dois
        laisser 13 x 6 = <span class="texteOrange">78 mètres</span>
      </p>
    </div>
    <div class="blocDroit">
      <figure>
        <img src="@/assets/vitesse-24-distance_securite.png" alt="" />
      </figure>
      <p class="texteOrange">Distance de sécurité = 2 secondes</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page23",
};
</script>

<style lang="scss" scoped>
.page26 {
  .blocGauche,
  .blocDroit {
    display: inline-block;
    width: 45vw;
    position: relative;
  }

  .blocGauche {
    bottom: 23vh;

    .calcul {
        margin: 1vh 0;

        span {  
            font-weight: bold;
        }

        img {
            vertical-align: middle;
            margin: 0 .3vw;
        }
    }
  }

  .blocDroit {
    bottom: 21vh;
    left: 8vw;
    p {
        text-align: center;
        font-size: 1.6rem;
    }
  }
}
</style>
<template>
  <div class="pages page28">
    <div class="titre">
      <h1>Quelques notions</h1>
      <h2>sur l’hypovigilance</h2>
    </div>
    <p class="commentaire">À vous de jouer !</p>
    <p class="commentaire texteOrange">Comment déceler les premiers signes ?</p>
    <div class="blocReponse" @click="onClick($event)">
      <div class="dot-flashing"></div>
      <div class="reponse hidden">
        <ul>
          <li>Picotements des yeux</li>
          <li>Raideurs dans la nuque</li>
          <li>Douleurs dans le dos</li>
          <li>Bâillements</li>
          <li>Changements fréquents de position</li>
          <li>Démangeaisons (on se gratte la tête)</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page28",

  methods: {
    onClick: function (e) {

      let target = e.target;

      if (!e.target.classList.contains('blocReponse')) {
        target = e.target.parentElement;
      }

      if (!target.firstElementChild.classList.contains("hidden") && (target.firstElementChild.tagName!= "LI")) {
        target.firstElementChild.classList.add("hidden");
        target.lastElementChild.classList.remove("hidden");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page28 {
  ul {
    list-style-type: none;

    li:before {
      content: "\25CF";
      margin-right: 1vw;
      color: #f68c59;
    }

    li {
      font-size: 1.6rem;
    }
  }

  .dot-flashing {
    top: 11vh;
    left: 45vw;
  }

  .blocReponse {
    height: 36vh;
    cursor: help;
  }

  .hidden {
    display: none;
  }
}
</style>
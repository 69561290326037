<template>
  <div class="pages page37">
    <div class="titre">
      <h1>Ouverture de portière</h1>
    </div>
    <div class="blocGauche">
      <miniPlayer
        url="https://player.vimeo.com/progressive_redirect/playback/770808350/rendition/1080p/file.mp4?loc=external&signature=f219d4061c291ddb88b6d2c4f9d2ad7c22951cce4c5ecd76b31a7e56cf63c2c1"
        thumbnail="thumbnail-portiere.jpg"
      ></miniPlayer>
    </div>
    <div class="blocDroit">
      <img src="@/assets/portiere.jpg" alt="" />
    </div>
    <div class="blocTexte">
      <p>
        Le conducteur d'un véhicule de tourisme en stationnement ouvre sa
        portière sans contrôle et surprend le conducteur de 2RM.
      </p>
    </div>
  </div>
</template>

<script>
import miniPlayer from "@/components/miniPlayer.vue";

export default {
  name: "Page37",

  components: {
    miniPlayer,
  },
};
</script>


<style lang="scss" scoped>
.page37 {
  .blocGauche,
  .blocDroit {
    display: inline-block;
    width: 40vw;
    padding: 0 1vw;
    overflow: hidden;
    height: 43vh;
    margin: 0 2.4vw;
  }

  .blocDroit {
    img {
      width: 40vw;
    }
  }

  .blocTexte {
    p {
      text-align: center;
    }
  }
}
</style>
<template>
  <div class="pages page62">
    <div class="titre">
      <h1>Airbag : gilet de protection</h1>
    </div>
    <div class="blocGauche">
      <p><strong>3 sortes d'airbag</strong></p>
      <ul>
        <li>Filaire par déclenchement mécanique</li>
        <li>Radiocommandé avec capteur sur le 2RM</li>
        <li>Autonome : capteur inclus dans le gilet airbag</li>
      </ul>
      <miniPlayer
        url="https://player.vimeo.com/progressive_redirect/playback/557976523/rendition/360p/file.mp4?loc=external&signature=bf5d013e4f24f34f4f1a5f422b73123f2c4e2b4949f51a77ff6310b9a32c22b0"
        thumbnail="thumbnail-airbag.jpg"
      ></miniPlayer>
    </div>
    <div class="blocDroit">
      <p>
        Solution de sécurité active. L'air bag constitue un progrès majeur dans
        les Equipements de Protection Individuelle. Un exemple, la solution
        Bering.
      </p>
      <img src="@/assets/bering.png" alt="" />
    </div>
    <figure>
      <img src="@/assets/veste-face.png" alt="" />
    </figure>
    <p class="sousMenu">Conseillés</p>
  </div>
</template>

<script>
import miniPlayer from "@/components/miniPlayer.vue";

export default {
  name: "Page62",

  components: {
    miniPlayer,
  },
};
</script>

<style lang="scss" scoped>
.page62 {
  .blocGauche {
    .videoWrapper {
      width: 34vw;
      margin: 0;
    }
  }

  figure {
    position: absolute;
    top: 0;
    left: 48vw;

    img {
      width: 27vw;
    }
  }

  .blocDroit {
    background-color: #f68c59;
    position: absolute;
    top: 0;
    right: 0;
    width: 35vw;
    height: 100%;

    p {
      color:white;
      padding: 5vw 5vw 0 5vw;
      text-align: right;
    }

    img {
      width: 21vw;
      padding-left: 9vw;
    }
  }

    .sousMenu {
    bottom: 70vh;
  }
}
</style>
<template>
  <div class="pages page30">
    <div class="blocCentral">
      <camembert dataFile="accidentalite-2rm.json" titre="Voies rapides et autoroutes"></camembert>
      <!--<camembert dataFile="accidentalite-peripherique.json" titre="Une singularité : Le périphérique parisien"></camembert>-->
      <p class="basPage">Source ONISR 2022</p>
    </div>
  </div>
</template>

<script>
import Camembert from '@/components/camembert.vue';

export default {
  name: "Page30",

  components: {
    Camembert,
  },

};
</script>

<style lang="scss" scoped>
.page30 {

  .blocCentral {
    //text-align: center;
  }

  .basPage {
    right: 3vh;
  }

  .pie {
   /* width: 35vw;
    display: inline-block;
    margin: 0 5vw;
    vertical-align: top;*/
    width: 100%;
    vertical-align: top;
}

  .percent {
    bottom: 12vh;
  }
}
</style>
<template>
  <div class="pages page63">
    <div class="titre">
      <h1>Bottes moto «Bike» de Paraboot</h1>
    </div>
    <div class="blocGauche">
        <p>Norme EN 1634 : 2010<br>Niveau 2 2 1 IPA IPS B<br>Fabrication Française<br></p>
        <p><img src="@/assets/gants-norme.jpg" alt=""></p>
    </div>
    <figure>
        <img src="@/assets/botte-moto.png" alt="">
    </figure>
    <p class="sousMenu">Conseillés</p>
  </div>
</template>

<script>
export default {
  name: "Page63",
};
</script>

<style lang="scss" scoped>
.page63 {

    .blocGauche {
        p {
            font-size: 1.4em;
        }

        img {
            width: 10vw;
        }
    }

    figure {
        position: absolute;
        top: 0;
        right: 5vw;
        img {
            width: 41vw;
        }
    }

    .sousMenu {
      bottom: 45vh;
    }
}
</style>
<template>
  <div class="pages page17">
    <div class="blocGauche">
      <div class="titre">
        <h1>Alcool + cannabis + conduite =</h1>
        <h2>Amplification des effets</h2>
      </div>
      <ul>
        <li>Le temps de réaction s'allonge,</li>
        <li>Les trajectoire sont imprécises,</li>
        <li>La concentration et l'attention diminuent,</li>
        <li>Les mouvements sont moins coordonnés,</li>
        <li>La perception du risque est erronée,</li>
        <li>La vue et l'ouïe sont perturbées…</li>
      </ul>
    </div>
    <div class="blocDroit">
      <figure>
        <img src="../assets/alcool-drogue.jpg" alt="" />
      </figure>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page17",
};
</script>

<style lang="scss" scoped>
.page17 {
  .blocGauche {
    width: 45vw;
  }

  .blocDroit {
    width: 49vw;
    height: 100%;
    background: linear-gradient(to right, transparent 29%, #f68c59 29%);
    position: absolute;
    top: 0;
    right: 0;

    img {
      width: 45vw;
      margin-top: 5vh;
      margin-right: 5vw;
    }
  }
}
</style>
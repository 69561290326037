<template>
  <div class="pages page11">
    <div class="blocGauche">
      <div class="titre">
        <h1>L’alcool et la conduite</h1>
        <h2>Conducteur novice</h2>
      </div>
<p>Depuis le 1<sup>er</sup> juillet 2015, <strong>la limite d'alcool autorisée pour les jeunes titulaires d'un permis probatoire passe de 0.5 g/l à 0.2 g/l d'alcool dans le sang</strong> (soit un taux de 0.1 mg/l air expiré).</p>
<p>Un seul verre d'alcool peut entrainer une amende de 135 €, 6 points en moins avec retrait de permis et immobilisation du véhicule.</p>
    </div>
    <div class="blocDroit">
    </div>
    <div class="blocImage"><img src="../assets/alcool-ethylometre.jpg" alt="" /></div>
    <p class="basPage">Source ONISR 2021</p>
  </div>
</template>

<script>
export default {
  name: "Page11",
};
</script>


<style lang="scss">
.page11 {
  .blocGauche {
    width: 40vw;
  }

  .blocDroit {
    position: absolute;
    right: 0;
    top: 0;
    width: 26vw;
    height: 100%;
    padding-left: 10vw;
    padding-top: 5vh;
    padding-right: 2vw;
    background-color: #f68c59;
    color: white;

    h3 {
      font-size: 5rem;
      margin-top: 1vh;
      margin-bottom: 0;
    }

    p {
      font-size: 1.6rem;
      margin-top: 0;
      margin-bottom: 6vh;
    }
  }

  .blocImage {
    position: absolute;
    bottom: -6px;
    left: 52vw;
    img {
      width: 25vw;
    }
  }

  .commentaire {
    margin-top: 6vh;
  }
}
</style>
<template>
  <div class="videoWrapper" @click="onPlayer($event)">
    <video class="player"
      preload="auto"
      :src="url"
      :poster="resolve_url(thumbnail)"
      controlslist="nodownload"
      disablePictureInPicture
      @ended="onEnd($event)"
      @play="onPlay($event)"
      @click="onPlay($event)"
    >
    </video>
    <div class="playpause" ></div>
  </div>
</template>

<script>
export default {
  name: "miniPlayer",
  props: {
    url: String,
    thumbnail: String,
  },
  methods: {
    onEnd: function (e) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
      e.target.load();
    },

    onPlay: function (e) {
      if (e.target.requestFullscreen) {
        e.target.requestFullscreen();
      } else if (e.target.webkitRequestFullscreen) {
        /* Safari */
        e.target.webkitRequestFullscreen();
      } else if (e.target.msRequestFullscreen) {
        /* IE11 */
        e.target.msRequestFullscreen();
      }
    },

    onPlayer: function (e) {
      e.target.parentElement.children[0].play();
    },

    onFullScreen: function (e) {
      if (document.fullscreenElement) {
        e.target.play();
        e.target.setAttribute("controls","controls");
      } else {
        e.target.pause();
        e.target.removeAttribute("controls")
      }
    },

    resolve_url: function (path) {
      let images = require.context("../assets/", false, /\.png$|\.jpg$/);
      return images("./" + path);
    },
  },

  created: function () {
    document.addEventListener("webkitfullscreenchange", this.onFullScreen);
    document.addEventListener("mozfullscreenchange", this.onFullScreen);
    document.addEventListener("fullscreenchange", this.onFullScreen);
  },
};
</script>

<style lang="scss">

.videoWrapper {
  display: table;
  position: relative;
  margin: auto;

  video {
    width: 100%;
  }

  .playpause {
    background-image: url("../assets/play-icon.png");
    background-repeat: no-repeat;
    position: absolute;
    left: 0%;
    right: 0%;
    top:0%;
    bottom: 0%;
    margin: auto;
    background-size: contain;
    background-position: center;
  }
}
</style>
<template>
  <div class="pages page12">
    <div class="titre">
      <h1>Équivalence des verres</h1>
    </div>
    <div class="gramme">
      <p>Alcool pur</p>
      <p class="valeur"></p>
    </div>
    <div class="alcool" v-for="alcool in alcools" :key="alcool.id" :id="alcool.id">
      <p class="verre">{{ alcool.volume }}</p>
      <img :src="resolve_url(alcool.image)" alt="" @click="onClick($event)" />
      <p class="nom">{{ alcool.type }}</p>
      <p class="valeur">&nbsp;</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page12",

  data() {
    return {
      alcools: [
        {
          id: 1,
          type: "Whisky",
          volume: "3 cl",
          pourcent: "40 %",
          gramme: "9.6 g",
          image: "whisky.png",
        },
        {
          id: 2,
          type: "Vin",
          volume: "10 cl",
          pourcent: "12 %",
          gramme: "9.6 g",
          image: "vin.png",
        },
        {
          id: 3,
          type: "Pastis",
          volume: "3 cl",
          pourcent: "45 %",
          gramme: "10.80 g",
          image: "pastis.png",
        },
        {
          id: 4,
          type: "Bière",
          volume: "25 cl",
          pourcent: "5 %",
          gramme: "10.00 g",
          image: "biere.png",
        },
      ],
    };
  },

  methods: {
    onClick: function (e) {
      let alcoolParent = e.target.parentElement;
      let oldSelected = document.querySelector(".actif");
      let grammeElement = document.querySelector(".gramme").lastElementChild;
      let id=(alcoolParent.id);

      if (oldSelected) {
        oldSelected.classList.toggle("actif");
        oldSelected.lastElementChild.innerHTML = "&nbsp;";
        grammeElement.innerHTML = "&nbsp;";
      }

      alcoolParent.classList.add("actif");
      alcoolParent.lastElementChild.innerHTML = this.alcools[id-1].pourcent;
      grammeElement.innerHTML = this.alcools[id-1].gramme;
    },

    resolve_url: function (path) {
      let images = require.context("../assets/", false, /\.png$|\.jpg$/);
      return images("./" + path);
    },
  },
};
</script>

<style lang="scss" scopped>
.page12 {
  .alcool {
    display: inline-block;
    margin: 0 6%;
    position: relative;
    top: -4vh;

    img {
      opacity: 0.5;
      cursor: pointer;
    }

    &.actif {
      img {
        opacity: 1;
      }
    }
  }

  .nom {
    text-align: center;
    font-weight: bold;
  }

  .valeur {
    border: 1px solid blue;
    height: 3vh;
    font-weight: bold;
    padding: 5%;
    text-align: center;
  }

  .gramme {
    position: absolute;
    top: 2vh;
    right: 10vw;
  }

  p.verre {
    position: relative;
    top: 15vh;
    left: 8vw;
  }
}
</style>
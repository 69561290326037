<template>
  <div class="pages page16">
    <div class="blocGauche">
      <div class="titre">
        <h1>Produits stupéfiants et alcool</h1>
        <h2>Risques d'accident</h2>
      </div>
      <p>
        La consommation de cannabis seule multiplie le risque d’accident
        par 1,8.
      </p>
      <p>Associée à l’alcool, le risque est alors multiplié par 14 !</p>
      <p>
        <strong>
          En 2021, on estime à 790 le nombre de tués dans un
          accident impliquant au moins un conducteur testé positif au cannabis...
        </strong>
      </p>
    </div>
    <div class="blocDroit">
      <figure>
        <img src="@/assets/alcool-medicament.jpg" alt="" />
      </figure>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page16",
};
</script>

<style lang="scss" scoped>
.page16 {
  .blocGauche {
    width: 45vw;
  }

  .blocDroit {
    width: 49vw;
    height: 100%;
    background: linear-gradient(to right, transparent 29%, #f68c59 29%);
    position: absolute;
    top: 0;
    right: 0;

    img {
      width: 45vw;
      margin-top: 5vh;
      margin-right: 5vw;
    }
  }
}
</style>
<template>
  <div class="pages page65">
    <div class="titre">
      <h1>Pantalon</h1>
    </div>
    <div class="blocGauche">
      <ul>
        <li>
          Il doit être assez large pour être <strong>confortable</strong>, mais
          suffisamment <strong>serré pour protéger des chocs,</strong>
        </li>
        <li>Être souple pour permettre au conducteur de se mouvoir,</li>
        <li>
          Être si possible muni de
          <strong>protections pour les hanches, les genoux,</strong> de
          préférence <strong>avec des protège-tibias</strong> incorporés, avec
          des empiècements de
          <strong>textile de haute visibilité</strong> (fluorescents et
          rétro-réfléchissants),
        </li>
        <li>Être équipé de <strong>protections aux genoux.</strong></li>
        <li>
          Si vous ne voulez pas porter un pantalon moto toute la journée, les
          magasins spécialisés proposent aussi des pantalons qui peuvent se
          transformer facilement grâce à des protections amovibles. Les
          fabricants ont également mis au point un pantalon en Kevlar® qui
          ressemble à un jean classique tout en assurant la protection
          nécessaire. Doté de renforts invisibles, il est pratique pour les
          conducteurs urbains.
        </li>
      </ul>
    </div>
    <div class="blocDroit">
      <aside>
        <img src="@/assets/gants-norme.jpg" alt="" />
        <p>Norme<br />EN 13595</p>
      </aside>
    </div>
    <figure><img src="@/assets/pantalon.png" alt="" /></figure>
    <p class="sousMenu">Conseillés</p>
  </div>
</template>

<script>
export default {
  name: "Page65",
};
</script>

<style lang="scss" scoped>
.page65 {
  .blocGauche {
    width: 45vw;
    li {
      margin-top: 2vh;
    }
  }
  .blocDroit {
    position: absolute;
    top: 0;
    right: 0;

    aside {
      position: absolute;
      top: 53vh;
      right: 10vw;

      img {
        width: 8vw;
      }

      p {
        text-align: center;
      }
    }
  }
  figure {
    position: absolute;
    top: 1vh;
    left: 58vw;

    img {
      width: 17vw;
    }
  }

    .sousMenu {
    bottom: 64vh;
    left: 82vw;
  }
}
</style>
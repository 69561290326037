<template>
  <div class="pie">
   <div class="titre"><h1>{{titre}}</h1></div> 
      
      <div id="legende">
            <p class="percent"></p>
        <p class="description"></p>
      </div>
      <div id="graph">
    <svg>
      <g v-for="(donnee, index) in donnees" :key="donnee" :id="index" @click="onClick($event)">
        <path class="arcFond" fill="none" stroke="#f68c59" stroke-width="20"></path>
        <path class="arcPercent" fill="none" stroke="#034EA2" stroke-width="20"></path>
      </g>
    </svg>

      </div>
    
  </div>
</template>

<script>
//import { ref } from 'vue'

export default {
  name: "camembert",

  props: {
    dataFile: String,
    titre: String,
  },

  data() {
    return {
      donnees: [],
      viewBoxMax: {
        x: 0,
        y: 0,
      },
      old: [],
    };
  },

  methods: {
    onClick: function (e) {

      var index = e.target.parentElement.id;

      e.target.parentElement.classList.add("select");
      if (this.old.length != 0) {
        this.old.classList.remove("select");
      }

      this.old = e.target.parentElement;

      this.$el.querySelector(".percent").innerText = this.donnees[index].percent + " %";
      this.$el.querySelector(".description").innerText = this.donnees[index].description;
    },

    arcInPercent: function (centerX, centerY, radius, percent) {
      percent = percent == 100 ? 99.99 : percent;
      var angleInRadians = (2 * Math.PI) / (100 / percent);

      var start = {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians),
      };

      var end = {
        x: centerX + radius * Math.cos(0),
        y: centerY + radius * Math.sin(0),
      };

      var largeArcFlag = angleInRadians <= Math.PI ? "0" : "1";

      var d = [
        "M",
        start.x,
        start.y,
        "A",
        radius,
        radius,
        0,
        largeArcFlag,
        0,
        end.x,
        end.y,
      ].join(" ");

      return d;
    },

    drawPie: function (element,percent,center,index) {

      var pathBackground = element.getElementsByClassName("arcFond")[0];
      var pathArc = element.getElementsByClassName("arcPercent")[0];

      var radius = 100+(index*25);
      
      pathBackground.setAttribute("d", this.arcInPercent(center.X, center.Y, radius, 100));
      pathArc.setAttribute("d", this.arcInPercent(center.X, center.Y, radius, percent));

      this.addRotateTransform (element,20);
    },

    addRotateTransform: function (element, duration) {
      //var my_element = svg.getElementById(target_id);
      var a = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "animateTransform"
      );
      //console.log(element);
      //console.log(duration)
      //console.log(element.parentElement);
      //console.log (a);

      var bb = element.parentElement.getBBox();
      var cx = bb.x + bb.width / 2;
      var cy = bb.y + bb.height / 2;
      a.setAttributeNS(null, "attributeName", "transform");
      a.setAttributeNS(null, "attributeType", "XML");
      a.setAttributeNS(null, "type", "rotate");
      a.setAttributeNS(null, "begin", Math.floor(Math.random()*2)+ "s");
      a.setAttributeNS(null, "dur", (Math.floor(Math.random()*5) + duration) + "s");
      a.setAttributeNS(null, "repeatCount", "indefinite");
      a.setAttributeNS(null, "from", "0 " + cx + " " + cy);
      a.setAttributeNS(null, "to", 360 + " " + cx + " " + cy);
      element.appendChild(a);
      //a.beginElement();
    },
  },

  mounted: async function () {

    await import(`@/assets/json/${this.dataFile}`).then((module) => {
      this.donnees = module.default;
    });

    const svgElement = this.$el.querySelector("svg"); //selectionne l'élément svg du composant

    var maxBox = {
      X: ((100+((this.donnees.length-1)*25))*2)+50,
      Y: ((100+((this.donnees.length-1)*25))*2)+50,
    }

    var center = {
      X : maxBox.X/2,
      Y : maxBox.Y/2,
    }

    svgElement.setAttribute("viewBox", "0 0 "+ maxBox.X + " " + maxBox.Y);

    const groupeElements = svgElement.getElementsByTagName('g');

    for (var i=0; i<= this.donnees.length-1; i++) {
      this.drawPie(groupeElements[i],this.donnees[i].percent,center,i);
    }
  },
};
</script>

<style lang="scss" scoped>

/*h1 {
  margin-bottom: .5vh;
}*/

svg {
  width: 550px;
  margin: 0 11%;

  g {
    cursor: pointer;
    opacity: .4;
  }
  
}

.select {
  opacity: 1;
}

#legende, #graph{
  display: inline-block;
  width: 45%;
  position: relative;
}

#legende {
  vertical-align: top;
}

#graph {
  margin-left:5vw;
  bottom: 12vh;
}

.percent {
    /*position: relative;
    bottom: 26vh;
    left: .4vw;
    margin: 0 11vw;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;*/
    font-size: 5rem;
    font-weight: bold;
}

.description {
    //position: relative;
    //bottom: 8vh;
    //text-align: center;
}
</style>
<template>
  <div class="pages page19">
    <div class="titre">
      <h1>Vitesse et poids à l'impact</h1>
    </div>
    <div class="blocCentral">
      <div id="1" class="gaugeContainer">
        <img src="@/assets/icone-compteur-usager.png" alt="" />
        <p class="poid"></p>
        <gauge :vitesse="30" @speed-update="gaugeUpdate"></gauge>
        <p class="energieLabel">Énergie cinétique</p>
        <p class="energie"></p>
      </div>
      <div id="2" class="gaugeContainer">
        <img src="@/assets/icone-compteur-2rm.png" alt="" />
        <p class="poid"></p>
        <gauge :vitesse="30" @speed-update="gaugeUpdate"></gauge>
        <p class="energieLabel">Énergie cinétique</p>
        <p class="energie"></p>
      </div>
      <div id="3" class="gaugeContainer">
        <img src="@/assets/icone-compteur-voiture.png" alt="" />
        <p class="poid"></p>
        <gauge :vitesse="30" @speed-update="gaugeUpdate"></gauge>
        <p class="energieLabel">Énergie cinétique</p>
        <p class="energie"></p>
      </div>
      <div id="4" class="gaugeContainer">
        <img src="@/assets/icone-compteur-bus.png" alt="" />
        <p class="poid"></p>
        <gauge :vitesse="30" @speed-update="gaugeUpdate"></gauge>
        <p class="energieLabel">Énergie cinétique</p>
        <p class="energie"></p>
      </div>
      <div id="5" class="gaugeContainer">
        <img src="@/assets/icone-compteur-camion.png" alt="" />
        <p class="poid"></p>
        <gauge :vitesse="30" @speed-update="gaugeUpdate"></gauge>
        <p class="energieLabel">Énergie cinétique</p>
        <p class="energie"></p>
      </div>
      <p>L'énergie cinétique, pour des raisons de compréhension, est exprimée ici en tonne.</p>
    </div>
    <div class="blocTop"><img src="@/assets/energie-poid-comparaison.png" alt=""></div>
  </div>
</template>

<script>
import Gauge from "@/components/gauge.vue";

export default {
  name: "Page19",

  data() {
    return { 
      cinetique: [
      {
        poid:0.075,
        energie: [0,0,0,0.265,0.472,0.737,1.062,1.445,1.888,2.389,2.950,3.569,4.247,4.958,5.781,6.637]
      },
            {
        poid:0.200,
        energie: [0,0,0,0.708,1.258,1.966,2.832,3.854,5.034,6.371,7.865,9.517,11.32,13.29,15.41,17.69]
      },
            {
        poid:0.900,
        energie: [0,0,0,3.186,5.663,8.849,12.74,17.34,22.65,28.67,35.39,42.82,50.96,59.81,69.37,79.63]
      },
            {
        poid:19.00,
        energie: [0,0,0,67.25,119.5,186.8,269.0,366.1,478.2,605.2,747.2,904.1,1076,1262,1464,1681]
      },
            {
        poid:38.00,
        energie: [0,0,0,134.5,239.1,373.6,538.0,732.2,956.4,1210,1494,1808,2151,2525,2929,3362]
      },
    ]

    };
  },

  components: {
    Gauge,
  },

  methods: {
    gaugeUpdate: function (speed, el) {
      el.getElementsByClassName("energie")[0].innerHTML = this.cinetique[el.id-1].energie[speed/10];
    },
  },

  mounted() {
    const elementBlocCentral = document.getElementsByClassName("blocCentral");
    const elementsBloc = elementBlocCentral[0].childNodes;
    for (let i=0; i<5; i++) {
      let elementPoid = elementsBloc[i].getElementsByClassName("poid");
      let elementEnergie = elementsBloc[i].getElementsByClassName("energie");
      elementPoid[0].innerHTML = this.cinetique[i].poid+" t";
      elementEnergie[0].innerHTML = this.cinetique[i].energie[3];
    }
  }
};
</script>

<style lang="scss">
.page19 {

  .blocCentral {
    text-align: center;
    position: relative;
    bottom: 2vh;

    p {
      font-weight: bold;
      text-align: center;
    }

    img {
      width: 5vw;
    }

    .poid {
      margin: 0 0 1vh 0;
    }

    .energieLabel{
      margin:0;
    }

    .energie {
    margin: 1vh 0;
    padding: 1vh 0;
    border: 1px solid #034EA2;
    font-size: 1.6rem;
    }
  }

  .blocTop {
    position: relative;
    left: 60vw;
    bottom: 72vh;
  }

  .gaugeContainer {
    display: inline-block;
    margin:0 2vw;
  }
}
</style>

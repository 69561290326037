<template>
  <div class="pages page14">
      <div class="titre">
        <h1>Repas entre amis</h1>
      </div>
       <img src="@/assets/repas_amis.png" alt="">
  </div>
</template>

<script>
export default {
  name: "Page14",
};
</script>

<style lang="scss">
.page14 {
    img {
    position: relative;
    bottom: 136px;
}
}
</style>
<template>
  <div class="pages page36">
    <div class="titre">
      <h1>Dépassement à risque</h1>
    </div>
    <div class="blocGauche">
      <miniPlayer
        url="https://player.vimeo.com/progressive_redirect/playback/771604117/rendition/1080p/file.mp4?loc=external&signature=148fdd01307a90594ea8e06633387029c2ec8652ece46bdae872399b86cc52e0"
        thumbnail="thumbnail-depassement.png"
      ></miniPlayer>
    </div>
    <div class="blocDroit">
      <img src="@/assets/depassement.jpg" alt="" />
    </div>
    <div class="blocTexte">
      <p>
        Le conducteur de 2RM effectue un dépassement de plusieurs véhicules. Sa
        trajectoire est coupée <br>par un véhicule de tourisme qui tourne à gauche
        pour s'engager sur une voie.
      </p>
    </div>
  </div>
</template>

<script>
import miniPlayer from "@/components/miniPlayer.vue";

export default {
  name: "Page36",

  components: {
    miniPlayer,
  },
};
</script>


<style lang="scss" scoped>
.page36 {
  .blocGauche,
  .blocDroit {
    display: inline-block;
    width: 40vw;
    padding: 0 1vw;
    overflow: hidden;
    height: 43vh;
    margin: 0 2.4vw;
  }

  .blocDroit {
    img {
      width: 40vw;
    }
  }

  .blocTexte {
    p {
      text-align: center;
    }
  }
}
</style>
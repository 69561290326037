<template>
  <div class="pages page9">
    <div class="blocGauche">
      <div class="titre">
        <h1>L’alcool et la conduite</h1>
        <h2>Une situation préoccupante</h2>
      </div>
      <p>
        22 % des accidents mortels impliquent un motocycliste ayant un taux d’alcool dépassant le taux
        légal.
      </p>
      <p>Cette part est de 42 % sur autoroute.</p>
      <p>
        33 % des cyclomotoristes impliqués dans un accident mortels sont
        alcoolisés.
      </p>
      <p>92 % impliquent un homme alcoolisé.</p>
    </div>
    <div class="blocDroit">
      <h3>65 %</h3>
      <p>des accidents mortels avec alcool ont lieu la nuit.</p>
      <h3>46 %</h3>
      <p>les week-ends et jours fériés.</p>
    </div>
    <div class="blocImage"><img src="@/assets/alcool-biere.jpg" alt="" /></div>
    <p class="basPage">Source ONISR 2021</p>
  </div>
</template>

<script>
export default {
  name: "Page9",
};
</script>


<style lang="scss">
.page9 {
  .blocGauche {
    width: 40vw;
  }

  .blocDroit {
    position: absolute;
    right: 0;
    top: 0;
    width: 26vw;
    height: 100%;
    padding-left: 10vw;
    padding-top: 5vh;
    padding-right: 2vw;
    background-color: #f68c59;
    color: white;

    h3 {
      font-size: 5rem;
      margin-top: 1vh;
      margin-bottom: 0;
    }

    p {
      font-size: 1.6rem;
      margin-top: 0;
      margin-bottom: 6vh;
    }
  }

  .blocImage {
    position: absolute;
    bottom: -6px;
    left: 44vw;
    img {
      width: 25vw;
    }
  }

  .commentaire {
    margin-top: 6vh;
  }
}
</style>
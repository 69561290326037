<template>
  <div id="menu">
    <div id="subMenu">
      <input id="panel-input" type="checkbox" />
      <label id="panel-label" for="panel-input">
        <img id="panel-icon" src="@/assets/subMenu.svg" alt="" />
      </label>
      <ul id="subMenuItems">
        <li
          v-for="linkItem in chapters[
            appState[$route.params.id_page - 1].activeChapter - 1
          ].links"
          :key="linkItem.title"
          @click="subClick(linkItem.page)"
        >
          {{ linkItem.title }}
        </li>
      </ul>
    </div>
    <div id="mainMenu">
      <ul>
        <li
          :class="{
            active: appState[$route.params.id_page - 1].activeChapter == 1,
          }"
          @click="goto(chapters[0].firstPage)"
        >
          État des lieux
        </li>
        <li
          :class="{
            active: appState[$route.params.id_page - 1].activeChapter == 2,
          }"
          @click="goto(chapters[1].firstPage)"
        >
          Législation
        </li>
        <li
          :class="{
            active: appState[$route.params.id_page - 1].activeChapter == 3,
          }"
          @click="goto(chapters[2].firstPage)"
        >
          Conduites à risques
        </li>
        <li
          :class="{
            active: appState[$route.params.id_page - 1].activeChapter == 4,
          }"
          @click="goto(chapters[3].firstPage)"
        >
          Équipements
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",

  methods: {
    goto: function (page) {
      this.$emit("close-side");
      var pageURL = "/page/" + page;
      this.$router.push({ path: pageURL });
    },
    subClick: function(page) {
      document.getElementById("panel-input").checked = false;
      this.goto(page);
    }
  },
};
</script>

<style lang="scss" scoped>
#menu {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 14vh;
  z-index: 100;
}

#subMenu {
  background: #f68c59;
  height: 100%;
  width: 32vw;
  float: left;
  position: relative;

  img {
    height: 45px;
  }

  #panel-icon {
    position: absolute;
    top: 3.5vh;
    left: 2vw;
    cursor: pointer;
  }

  #subMenuItems {
    background: #f68c59;
    //width: 100%;
    height: auto;
    clear: both;
    overflow: hidden;
    transition: all 0.5s ease;
    margin: 7.8vw 0 0 0;
    padding: 0vw;
    list-style: none;
    top: -450px;
    position: relative;
    z-index: -1;

    li {
      //float: left;
      font-size: 3vh;
      font-weight: bold;
      color: white;
      padding: 1vw;
      cursor: pointer;

      &:first-child {
        padding-top: 8vh;
      }

      &:last-child {
        padding-bottom: 8vh;
      }

      a {
        display: block;
        color: white;
        text-align: center;
        //padding: 5vh;
        text-decoration: none;
      }
    }
  }
}

#mainMenu {
  background: #034ea2;
  width: 68vw;
  position: relative;
  left: 32vw;
  height: inherit;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: absolute;
    left: 0px;

    li {
      float: left;
      font-size: 1.6rem;
      font-weight: bold;
      color: white;
      display: inline-block;
      padding: 5vh;
      cursor: pointer;

      a {
        display: block;
        color: white;
        text-align: center;
        padding: 5vh;
        text-decoration: none;
      }

      &.active {
        color: #f68c59;
      }
    }
  }
}

#panel-input {
  position: absolute;
  left: -999em;

  &:checked {
    ~ #panel-label {
      left: 196%;

      #panel-icon {
        left: 6vw;
        transform: translateX(13vw);
      }
    }

    ~ #subMenuItems {
      height: auto;
      transform: translateY(52vh);
    }
  }
}

#panel-icon {
  transition: all 0.5s;
}
</style>
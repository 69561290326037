<template>
  <div class="pages page3">
    <div class="blocGauche">
      <h2>Le 2RM est l'activité humaine la plus accidentogène</h2>
      <div class="commentaire2">
        <h3>2RM</h3>
        <p>718 décès et près de 5 400 blessés graves pour 3 millions d’usagers.</p>
      </div>
    </div>
    <div class="blocDroit">
      <div class="commentaire2">
        <h3>Accidents domestiques</h3>
        <p>Près de 20 000 décès par an pour 65 millions de personnes.</p>
      </div>
    </div>
    <figure><img src="@/assets/accidents-domestiques.jpg" alt="" /></figure>
    <p class="basPage">Source ONISR 2022</p>
  </div>
</template>

<script>
export default {
  name: "Page3",
};
</script>


<style lang="scss">
.page3 {
  .blocGauche {
    width: 40vw;

    .commentaire2 {
      margin-top: 9vh;
      margin-left: 6vw;
    }
  }

  .blocDroit {
    position: absolute;
    right: 0;
    top: 0;
    width: 28vw;
    height: 100%;
    padding-left: 9vw;
    padding-top: 5vh;
    padding-right: 2vw;
    background-color: #f68c59;
    color: white;

    .commentaire2 {
      margin-top: 17vh;
      margin-left: 7vw;

      h3 {
        font-size: 3rem;
        line-height: 6vh;
      }
    }
  }

  .commentaire {
    margin-top: 6vh;
  }

  figure {
    position: absolute;
    top: 7vh;
    left: 46vw;
  }

  img {
    width: 25vw;
  };
}
</style>
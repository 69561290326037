<template>
  <div class="pages page22">
    <div class="titre">
      <h1>Vitesse et distance d’arrêt</h1>
    </div>
    <div class="blocCentral">
      <div>
        <p>Distance parcourue pendant le temps de réaction</p>
        <p>+</p>
        <p>Distance de freinage</p>
        <p>=</p>
        <p>Distance d’arrêt</p>
      </div>
      <p class="commentaire texteOrange">
        Plus on circule à vitesse élevée,<br> plus la distance nécessaire pour
        s’arrêter augmente !
      </p>
      <p>
        Pour un individu dans un état physique et psychique «normal», le temps
        de réaction est d’environ <span class="texteOrange">1 seconde</span>.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page22",
};
</script>

<style lang="scss" scoped>
.page22 {
  .blocCentral {
    div {
      text-align: center;
      margin: 9vh 0;
      p {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        margin: 0;

        &:nth-child(odd) {
          width: 20vw;
          font-size: 2rem;
          font-weight: bold;
        }

        &:nth-child(even) {
          color: #f68c59;
          font-size: 4rem;
          font-weight: bold;
          width: 13vw;
          line-height: 5rem;
        }
      }
    }

    .commentaire {
        font-size: 2rem;
        margin: 0 0 8vh 0;
    }

    p:last-child {
        font-size: 1.6rem;
        text-align: center;
    }
  }
}
</style>
<template>
<div class="pages page26">
    <div class="titre">
        <h1>Quelques notions</h1>
        <h2>sur l’hypovigilance</h2>
    </div>
    <p class="commentaire">On ne peut pas accumuler du sommeil d'avance,
les dettes de sommeil s'additionnent.</p>
    <p class="commentaire">1 h de sommeil en moins par jour <span class="texteOrange">=</span> 1 nuit blanche en fin de semaine </p>
    <p>L'alcool et les drogues <span class="texteOrange">altèrent</span> le sommeil et la vigilance.</p>
</div>
</template>

<script>
export default {
  name: "Page26",
};
</script>

<style lang="scss" scoped>
.page26 {

    p {
        text-align: center;
        margin: 9vh 0;

        &.commentaire {
            .texteOrange {
                font-size: 4rem;
                margin: 0 2vw;
                vertical-align: middle;
            }
        }
    }
}
</style>
<template>
  <div class="pages page25">
    <div class="titre">
      <h1>Quelques notions</h1>
      <h2>sur l’hypovigilance</h2>
    </div>
    <p>
      L’activité de conduite nécessite
      <span class="texteOrange">une vigilance optimale.</span>
    </p>
    <p class="commentaire">
      L’hypovigilance est un état intermédiaire entre la veille et le sommeil
      dans lequel les facultés d’attention, d’observation, d’analyse et d’action
      sont réduites.
    </p>
    <p>
      Le facteur
      <span class="texteOrange">«malaise et fatigue»</span> représente toujours
      autour de <span class="texteOrange">8 %</span> des accidents mortels
      (constant depuis 2008). Beaucoup plus sur autoroute (plus de 20 %).
    </p>
    <p class="commentaire">
      <span class="texteOrange">5 h</span> de sommeil /<span class="texteOrange"
        > 24 h</span
      >
      = Risque d’accident <span class="texteOrange">X 2,7</span><br />
      Conduire entre <span class="texteOrange">2 h</span> et
      <span class="texteOrange">6 h</span> du matin = Risque d’accident
      <span class="texteOrange">X 5,6</span><br />
      <span class="texteOrange">24 h</span> de veille = effets d’une alcoolémie
      de <span class="texteOrange">0,50 mg/l</span> d’air expiré !
    </p>
  </div>
</template>

<script>
export default {
  name: "Page25",
};
</script>

<style lang="scss" scoped>
.page25 {
  p {
    text-align: center;
  }
}
</style>
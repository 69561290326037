<template>
  <div class="pages page7">
    <div class="titre">
      <h1>Panneaux de signalisation</h1>
    </div>
    <div class="blocCentral">
      <svg
        v-for="(panneau, index) in panneaux"
        :key="panneau"
        :id="index"
        @click="showModal(index)"
      >
        <use
          :xlink:href="'/panneaux-sprite.svg#' + index"
          :href="'/panneaux-sprite.svg#' + index"
        ></use>
      </svg>
    </div>
    <div class="blocModal hidden" @click="hideModal()">
      <div>
        <p id="description"></p>
        <svg viewBox="0 0 64 86">
          <use id="panneau" xlink:href="" href=""></use>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page7",

  data() {
    return {
      //description: "",
      panneaux: [
        { panneau: "Virage à droite" },
        { panneau: "Succession de virages le premier est à gauche" },
        { panneau: "Priorité uniquement à l'intersection annoncée" },
        { panneau: "Chaussée glissante" },
        { panneau: "Ralentisseur type dos d'âne" },
        { panneau: "Passage d'animaux sauvages" },
        { panneau: "Chaussée rétrécie par la droite" },
        { panneau: "Passage à niveau sans barrière" },
        { panneau: "Chutes de pierres" },
        { panneau: "Débouché de cyclistes" },
        { panneau: "Traversée de voies de tramways " },
        { panneau: "Passage à niveau avec barrière" },
        { panneau: "Endroit fréquenté par des enfants" },
        { panneau: "Passage pour piétons" },
        { panneau: "Autres dangers" },
        { panneau: "Descente dangereuse à 10 %" },
        { panneau: "Annonce de feux tricolores" },
        { panneau: "Circulation dans les deux sens" },
        { panneau: "Carrefour à sens giratoire" },
        { panneau: "Intersection avec priorité à droite" },
        { panneau: "Cédez le passage" },
        { panneau: "Présignalisation d'un cédez-le-passage" },
        { panneau: "Autres dangers (temporaires)" },
        { panneau: "Annonce de signaux lumineux (temporaires)" },
        { panneau: "Bouchon (temporaire)" },
        { panneau: "Travaux, prudence (temporaires)" },
        { panneau: "Projection de gravillons (temporaire)" },
        { panneau: "Arrêt à l'intersection obligatoire" },
        { panneau: "Sens interdit" },
        { panneau: "Circulation interdite dans les deux sens" },
        { panneau: "Interdiction de faire demi-tour" },
        { panneau: "Interdiction de dépasser sauf deux-roues" },
        { panneau: "Distance minimale entre les véhicules" },
        { panneau: "Largeur maximale" },
        { panneau: "Hauteur maximale" },
        { panneau: "Cédez le passage à la circulation en sens inverse" },
        { panneau: "Autres interdictions" },
        { panneau: "Stationnement interdit" },
        { panneau: "Arrêt et stationnement interdits" },
        { panneau: "Accès interdit à tous véhicules à moteur" },
        { panneau: "Accès interdit aux piétons" },
        { panneau: "Interdiction de tourner à droite" },
        { panneau: "Fin de toutes les interdictions" },
        { panneau: "Fin d'interdiction de dépasser" },
        { panneau: "Fin de limitation de vitesse" },
        { panneau: "Fin d'interdiction de signaux sonores" },
        { panneau: "Sortie d'une zone à stationnement payant" },
        { panneau: "Sortie d'un axe à vitesse limitée à 30km/h" },
        { panneau: "Indication d'une route prioritaire" },
        { panneau: "Indication de fin d'une route prioritaire" },
        { panneau: "Chemin obligatoire pour piétons" },
        { panneau: "Vitesse minimale obligatoire" },
        { panneau: "Obligation de tourner à droite" },
        { panneau: "Voie réservée aux transports en commun" },
        { panneau: "Contournement obligatoire par la droite" },
        { panneau: "Direction obligatoire tout droit" },
        {
          panneau:
            "Direction obligatoire à la prochaine intersection, tout droit ou à droite",
        },
        {
          panneau: "Direction obligatoire à la prochaine intersection à droite",
        },
        { panneau: "Réduction du nombre de voies" },
        {
          panneau:
            "Demi-tour, arrêt, stationnement interdits et feux de croisement obligatoires",
        },
        {
          panneau:
            "Sans unique pour les véhicules motorisés mais double sens pour les vélos",
        },
        {
          panneau:
            "Entrée dans zone de rencontre - priorité au piéton circulant sur la chaussée",
        },
        { panneau: "Vitesse conseillée" },
        { panneau: "Impasse" },
        { panneau: "Signalisation des têtes d'îlots" },
        {
          panneau:
            "Priorité par rapport à la circulation venant en sens inverse",
        },
        { panneau: "Poste de secours" },
        { panneau: "Poste d'appel d'urgence" },
        { panneau: "Circulation à sens unique" },
        { panneau: "Fin de route à accès réglementé" },
        { panneau: "Voies affectées" },
        { panneau: "Surélévation de la chaussée" },
        { panneau: "Emplacement d'arrêt d'urgence" },
        { panneau: "Piste ou bande cyclable" },
        { panneau: "Voie de détresse à droite" },
        { panneau: "Risque d'incendie" },
      ],
    };
  },

  methods: {
    showModal: function (index) {
      document.querySelector("#description").innerHTML =
        this.panneaux[index].panneau;
      document
        .querySelector("#panneau")
        .setAttribute("href", "/panneaux-sprite.svg#" + index);
      document
        .querySelector("#panneau")
        .setAttributeNS(
          "http://www.w3.org/1999/xlink",
          "xlink:href",
          "/panneaux-sprite.svg#" + index
        );
      document.querySelector(".blocModal").classList.remove("hidden");
    },

    hideModal: function () {
      document.querySelector(".blocModal").classList.add("hidden");
    },

    resolve_url: function (file) {
      let images = require.context("../assets/", false, /\.png$|\.jpg$|\.svg$/);
      return images("./" + file);
    },
  },
};
</script>

<style lang="scss" scoped>
.page7 {
  .blocCentral {
    position: relative;
    bottom: 1vh;

    svg {
      display: inline-block;
      width: 4.6vw;
      margin: 0 0.45vw;
      height: 14vh;
      margin: 0px 0;
      position: relative;
      bottom: 3vh;
      cursor: pointer;

      -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
      filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
    }
  }

  .blocModal {
    width: 100vw;
    height: 100%;
    background-color: rgba(80, 80, 80, 0.4);
    margin: 0;
    position: absolute;
    left: 0px;
    top: 0;
    cursor: pointer;

    div {
      background-color: #034ea2;
      width: 54vw;
      height: 25vh;
      margin: 20vh 25vw;
    }

    p {
      color: white;
      font-size: 1.6rem;
      font-weight: bold;
      width: 30vw;
      text-align: center;
      position: relative;
      left: 22vw;
      top: 25%;
    }

    svg {
      display: block;
      width: 24vw;
      position: relative;
      bottom: 23vh;

      -webkit-filter: drop-shadow(10px 10px 8px rgba(0, 0, 0, 0.7));
      filter: drop-shadow(10px 10px 8px rgba(0, 0, 0, 0.7));
    }
  }

  .hidden {
    display: none;
  }
}
//
</style>
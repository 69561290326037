<template>
  <div class="pages page27">
    <div class="titre">
      <h1>Vigilance au cours de la journée</h1>
    </div>
    <figure><img src="@/assets/hypovigilance-graph.png" alt="" /></figure>
  </div>
</template>

<script>
export default {
  name: "Page27",
};
</script>

<style lang="scss" scoped>
.page27 {
  figure {
    position: relative;
    top: -6vh;
    height: 58vh;
    margin: 0;
  }
}
</style>
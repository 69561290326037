<template>
  <div class="pages page53">
    <div class="blocGauche">
      <div class="titre">
        <h1>Tenir compte</h1>
        <h2>de son environnement</h2>
      </div>
      <p>
        L’usager de 2RM est surexposé aux risques générés par l’environnement :
        plots anti-stationnement, lampadaires, angles morts, porte-à-faux,
        travaux, hydrocarbures sur la chaussée, stationnements anarchiques...
      </p>
      <p class="commentaire">
        C’est à l’usager d’adapter son comportement en analysant continuellement
        ces facteurs de risque !
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: "Page53",

};
</script>

<style lang="scss" scoped>
.page53 {
  .blocGauche {
    width: 90vw;
  }

  .commentaire {
    margin-top: 14vh;
  }

}
</style>
<template>
  <div class="pages page20">
    <div class="titre">
      <h1>Vitesse et perception<br />visuelle</h1>
    </div>
    <div class="blocGauche">
      <p class="label">Champ visuel:</p>
      <p class="degre">{{ degre }}°</p>
      <gauge :vitesse="vitesses" @speed-update="degreeUpdate"></gauge>
    </div>
    <figure>
      <img id="vue" src="@/assets/vitesse-champ100.jpg" alt="" />
    </figure>
  </div>
</template>

<script>
import Gauge from "@/components/gauge.vue";

export default {
  name: "Page20",

  data() {
    return {
      vitesses: [40, 70, 100, 130],
      degres: [100, 75, 45, 30],
      degre: 100,
      nomImages: [
        "vitesse-champ100.jpg",
        "vitesse-champ75.jpg",
        "vitesse-champ45.jpg",
        "vitesse-champ30.jpg",
      ],
    };
  },

  components: {
    Gauge,
  },

  methods: {
    degreeUpdate: function (speed) {
      const index = this.vitesses.findIndex(
        (actualSpeed) => actualSpeed === speed
      );
      this.degre = this.degres[index];
      document.getElementById("vue").src = this.resolve_url(this.nomImages[index]);
    },

    resolve_url: function (file) {
      let images = require.context("../assets/", false, /\.png$|\.jpg$/);
      return images("./" + file);
    },
  },
};
</script>

<style lang="scss" scoped>
.page20 {
  .blocGauche {
    width: 24vw;
    height: 46vh;

    p {
      text-align: center;
      font-weight: bold;
       font-size: 1.6rem;
    }
  }
  .gauge {
    margin: 6vh 5vw 0;
  }

  .degre {
    margin: 1vh 6vw;
    padding: 1vh 0;
    border: 1px solid #034EA2;
  }

  figure {
    position: relative;
    left: 29vw;
    width: 66vw;
    margin: 0;
    bottom: 68vh;
  }

  img {
    width: 100%;
  }
}
</style>
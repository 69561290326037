<template>
  <div class="pages page4">
    <div class="blocGauche">
      <div class="titre">
        <h1>Focus :</h1>
        <h2>Accidents mortels 2RM</h2>
      </div>
      <p>
        Depuis 2000, la mortalité 2RM a baissé de seulement
        -35 % alors que les autres catégories d’usagers
        enregistrent une baisse de -66 % !
      </p>
      <p>
        Les accidents mortels se produisent dans 80 % des cas
        par beau temps et chaussée sèche. 38 % des décès des
        motocyclistes ont eu lieu le week-end ou un jour férié.
        La mortalité est faible la nuit, importante l'après-midi.
      </p>
      <p>
        La responsabilité des usagers de 2RM dans les accidents mortels varie
        entre 58 % et 76 % depuis 2000, contre
        63 % pour les autres usagers. Et
        39 % des accidents mortels 2RM sont sans tiers
        identifié.
      </p>
    </div>
    <div class="blocDroit">
      <div class="commentaire2">
        <h3>85 %</h3>
        <p>
          des utilisateurs de 2RM sont des hommes, 90 % des tués 2RM sont des
          hommes.
        </p>
      </div>
      <div class="commentaire2">
        <h3>61 %</h3>
        <p>
          des cyclomotoristes se tuent hors agglomération (59 % pour l'ensemble des usagers).
        </p>
      </div>
    </div>
    <p class="basPage">Source ONISR 2022</p>
  </div>
</template>

<script>
export default {
  name: "Page4",
};
</script>


<style lang="scss">
.page4 {
  .blocGauche {
    width: 44vw;
  }

  .blocDroit {
    position: absolute;
    right: 0;
    top: 0;
    width: 26vw;
    height: 100%;
    padding-left: 10vw;
    padding-top: 5vh;
    padding-right: 2vw;
    background-color: #f68c59;
    color: white;
  }

  .blocImage {
    position: absolute;
    bottom: -6px;
    left: 44vw;
    img {
      width: 25vw;
    }
  }

  .commentaire {
    margin-top: 6vh;
  }
}
</style>
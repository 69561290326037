<template>
  <Header></Header>
  <Content></Content>
  <Nav></Nav>
</template>

<script>
import Header from "@/components/Header.vue";
import Nav from "@/components/Nav.vue";
import Content from "@/components/Content.vue";

export default {
  name: "Page",

  components: {
    Header,
    Content,
    Nav,
  },
};
</script>


<template>
  <div class="pages page8">
    <div class="blocGauche">
      <div class="titre">
        <h1>Infractions</h1>
      </div>
      <table>
        <tr>
          <td colspan="5" class="cellHeader">Tarif des amendes en €</td>
        </tr>
        <tr>
          <td class="cellTitle">Classe</td>
          <td class="cellTitle">Minorée <br /><span>3 à 15 jours</span></td>
          <td class="cellTitle">
            Forfaitaire <br /><span>sous 45 jours</span>
          </td>
          <td class="cellTitle">Majorée <br /><span>Après 45 jours</span></td>
          <td class="cellTitle">Amende maximale</td>
        </tr>
        <tr>
          <td class="cellClasse">1</td>
          <td class="cellStd">-</td>
          <td class="cellStd">11</td>
          <td class="cellStd">33</td>
          <td class="cellStd">38</td>
        </tr>
        <tr>
          <td class="cellClasse">2</td>
          <td class="cellStd">22</td>
          <td class="cellStd">35</td>
          <td class="cellStd">75</td>
          <td class="cellStd">150</td>
        </tr>
        <tr>
          <td class="cellClasse">3</td>
          <td class="cellStd">45</td>
          <td class="cellStd">68</td>
          <td class="cellStd">180</td>
          <td class="cellStd">450</td>
        </tr>
        <tr>
          <td class="cellClasse">4</td>
          <td class="cellStd">90</td>
          <td class="cellStd">135</td>
          <td class="cellStd">375</td>
          <td class="cellStd">750</td>
        </tr>
        <tr>
          <td class="cellClasse">5</td>
          <td class="cellStd">-</td>
          <td class="cellStd">-</td>
          <td class="cellStd">-</td>
          <td class="cellStd">1 500<sup>*</sup></td>
        </tr>
      </table>
    </div>
    <div class="blocDroit">
      <label for="infraction">Nature du délit ou de l'infraction</label>
      <div class="box">
        <select name="delit" id="infraction" @change="onChange($event)">
          <option
            v-for="(item, key) in donnees"
            :key="item.nature"
            :value="key"
          >
            {{ item.nature }}
          </option>
        </select>
      </div>

      <div class="sanctions">
        <p>Classe des amendes ou délits</p>
        <p>{{classe}}</p>
      </div>
      <div class="sanctions">
        <p>Perte de point</p>
        <p>{{point}}</p>
      </div>
      <div class="sanctions">
        <p>Amende maximun fixée par le tribunal</p>
        <p>{{amende}}</p>
      </div>
      <div class="sanctions">
        <p>Condamnation maximun fixée par le tribunal</p>
        <p>{{prison}}</p>
      </div>
      <div class="sanctions">
        <p>Suspension du permis (maximum)</p>
        <p>{{suspension}}</p>
      </div>
      <div class="sanctions">
        <p>Annulation du permis (maximum)</p>
        <p>{{annulation}}</p>
      </div>
      <p class="basPage"><sup>*</sup>3 000 € en cas de récidive</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page8",

  data() {
    return {
      donnees: [],
      classe: "-",
      point: "-",
      amende: "-",
      prison: "-",
      suspension: "-",
      annulation: "-"
    };
  },

  methods: {
    onChange(event) {
      this.classe = this.donnees[event.target.value].classe;
      this.point = this.donnees[event.target.value].point;
      this.amende = this.donnees[event.target.value].amende;
      this.prison = this.donnees[event.target.value].prison;
      this.suspension = this.donnees[event.target.value].suspension;
      this.annulation = this.donnees[event.target.value].annulation;
    },
  },

  mounted: async function () {
    await import(`@/assets/json/infractions.json`).then((module) => {
      this.donnees = module.default;
    });

      this.classe = this.donnees[0].classe;
      this.point = this.donnees[0].point;
      this.amende = this.donnees[0].amende;
      this.prison = this.donnees[0].prison;
      this.suspension = this.donnees[0].suspension;
      this.annulation = this.donnees[0].annulation;
  },
};
</script>


<style lang="scss">
.page8 {
  .blocGauche {
    width: 50vw;
  }

  .blocDroit {
    position: absolute;
    right: 0;
    top: 0;
    width: 46vw;
    height: 90%;
    padding-left: 5vw;
    padding-top: 6vh;
    padding-right: 2vw;

    /*span {
    background-color: #0563af;
    color: white;
    position: relative;
    left: 4vw;
    padding: 2vh 3vw;
    font-weight: bold;
    }*/

    .sanctions {
      text-align: center;
      width: 100%;
      height: 7vh;

      p {
        display: inline-block;
      }

      p:first-child {
        width: 70%;
      }
      p:last-child {
        width: 14%;
        text-align: center;
        background-color: #0563af;
        color: white;
        //position: relative;
        //left: 4vw;
        padding: 2vh 3vw;
        font-weight: bold;
      }
    }
  }

  .box {
    position: relative;
    top: 4vh;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-bottom: 6vh;
  }

  .box select {
    background-color: #0563af;
    color: white;
    padding: 12px;
    width: 46vw;
    border: none;
    font-size: 1rem;
    -webkit-appearance: button;
    appearance: button;
    outline: none;
  }

  .box::before {
    content: "▼";
    position: absolute;
    top: 0;
    right: 0;
    width: 4vw;
    height: 100%;
    text-align: center;
    font-size: 1.2rem;
    line-height: 5.9vh;
    color: white;
    background-color: #f68c59;
    pointer-events: none;
  }

  /*.box:hover::before {
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.2);
}*/

  .box select option {
    padding: 30px;
  }

  .cellStd {
    background-color: #ddd;
    color: #034ea2;
    text-align: center;
    width: 10vw;
    font-weight: bold;
  }

  .cellClasse {
    background-color: #aaa;
    color: #034ea2;
    text-align: center;
    width: 4vw;
    height: 4vw;
    font-weight: bold;
  }

  .cellTitle {
    background-color: #034ea2;
    color: white;
    text-align: center;
    font-weight: bold;
    height: 4vw;

    span {
      font-weight: 100;
    }
  }

  .cellHeader {
    background-color: #f68c59;
    color: white;
    text-align: center;
    height: 3vw;
    font-weight: bold;
    text-transform: uppercase;
  }

  .commentaire {
    margin-top: 6vh;
  }
}
</style>
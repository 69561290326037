<template>
  <div class="pages page49">
    <div class="titre">
      <h1>Le danger,</h1>
      <h2>c’est les autres</h2>
    </div>
    <div class="blocGauche">
      <p class="commentaire">L’accident n’a rien à voir avec la fatalité !</p>
      <p>Il est toujours le résultat d’un cumul de causes sur lesquelles le conducteur peut le plus souvent AGIR !
      </p>
    </div>
    <div class="blocDroit">
      <figure><img src="../assets/regle-moto_accident-2.jpg" alt="" /></figure>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page49",
};
</script>

<style lang="scss" scoped>
.page49 {
  .blocGauche {
    width: 40vw;
  }

  .blocDroit {
    position: absolute;
    top: 0vh;
    right: 0vw;
    background: linear-gradient(to right, transparent 21%, #f68c59 21%);
    width: 45vw;
    height: 100%;
    margin: 0;

    img {
      width: 28vw;
      display: block;
      margin-top: 5vh;
    }
  }
}
</style>